@if (notes.length) {
  <div class="box-inverted">
    <div class="title">
      <h2>Notes list</h2>
    </div>
    <div class="timeline">
      @for (note of notes; track note.id) {
        <div class="box">
          <div class="timeline-date">
            {{note.created_at * 1000 | date: 'd MMMM, y HH:mm'}}
          </div>
          <div class="note-content">
            <p>{{note.text}}</p>
            <div class="edit-option">
              <mat-icon class="material-icons-outlined" matTooltip="Edit" routerLink="/notes/{{note.id}}">edit</mat-icon>
            </div>
          </div>

        </div>
      }
    </div>
  </div>
} @else {
  @if (loading) {
    <div class="loader">
      <mat-progress-spinner strokeWidth="2" mode="indeterminate"></mat-progress-spinner>
    </div>
  } @else {
    <app-no-records-screen
      [title]="'You still don\'t have any notes'"
      [description]="'To get your first note, please use the top right menu.'"
      [icon]="'event_note'"
    ></app-no-records-screen>
  }
}
