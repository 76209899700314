import {Component, DestroyRef, OnInit} from '@angular/core';
import {TransactionsService} from "../../services/transactions.service";
import {ListTransactionsComponent} from "../../components/list-transactions/list-transactions.component";
import {ListNotesComponent} from "../../components/list-notes/list-notes.component";
import {ContainerScrollService} from "../../shared/container-scroll.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-transactions',
  standalone: true,
    imports: [
        ListTransactionsComponent,
        ListNotesComponent
    ],
  templateUrl: './transactions.component.html',
  styleUrl: './transactions.component.css'
})
export class TransactionsComponent implements OnInit {
  title: string = 'Transactions';
  page: number = 1;
  limit: number = 50;
  transactions: any = [];
  loading: boolean = true;
  hasMorePages: boolean = true;

  constructor(
    private transactionsService: TransactionsService,
    private containerScrollService: ContainerScrollService,
    private destroyRef: DestroyRef
  ) {

  }

  ngOnInit() {
    this.getData();

    this.containerScrollService.scroll$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(event => {
      if (event !== null) {
        if ((event.target.scrollTop + event.target.offsetHeight) >= (event.target.scrollHeight - 300)) {
          if (!this.loading && this.hasMorePages) {
            ++this.page;
            this.getData();
          }
        }
      }
    });
  }

  private getData(): void {
    this.loading = true;
    this.transactionsService.fetch(this.page).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(r => {
      if (!r.error) {
        r.data.transactions.forEach((item: any) => this.transactions.push(item));
        this.hasMorePages = r.data.transactions.length === this.limit;
        this.loading = false;
      }
    });
  }
}
