import {range} from "rxjs";

export default class Carousel
{
  private container: any;
  private scrollableArea: any;
  private scrollableAreaSelector!: string;
  private scrollWidth!: number;
  private offsetWidth!: number;
  private reservedOffsetWidth!: number;
  private reservedOffsetWidthSelector?: string;
  private scrollPosition!: number

  private currentPage: number = 1;
  private nextPage: number = 2;
  private prevPage: number = 1;
  private totalPages!: number;

  addContainer = (container: any, selector: string = '#cards-list', reservedOffsetWidthSelector?: string) => {
    this.container = container;
    this.scrollableArea = container.nativeElement.querySelector(selector);
    this.scrollableAreaSelector = selector;
    this.reservedOffsetWidthSelector = reservedOffsetWidthSelector;
  }

  calculate = () => {
    this.scrollWidth = this.scrollableArea.scrollWidth;
    this.offsetWidth = this.container.nativeElement.offsetWidth;
    this.scrollPosition = this.container.nativeElement.scrollLeft;

    if (this.reservedOffsetWidthSelector) {
      this.reservedOffsetWidth = this.container.nativeElement.querySelector(this.reservedOffsetWidthSelector).offsetWidth;
      this.offsetWidth -= this.reservedOffsetWidth;
    }

    this.totalPages = Math.round(this.scrollWidth / this.offsetWidth);
    this.currentPage = 1;
    this.nextPage = 2;
    this.prevPage = 1;

    this.scrollToCurrentPage();
  }

  gotoNextPage = () => {
    ++this.currentPage;

    if (this.currentPage > this.totalPages) {
      this.currentPage = 1;
      this.nextPage = 2;
      this.prevPage = 1;
    } else {
      this.nextPage = this.currentPage + 1;
      this.prevPage = this.currentPage - 1;
    }

    this.scrollToCurrentPage();
  }

  gotoPreviousPage = () => {
    --this.currentPage;

    if (this.currentPage <= 0) {
      this.currentPage = this.totalPages;
      this.nextPage = this.currentPage + 1;
      this.prevPage = this.currentPage - 1;
    } else {
      this.nextPage = this.currentPage + 1;
      this.prevPage = this.currentPage - 1;
    }

    this.scrollToCurrentPage();
  }

  gotoPage = (page: number) => {
    this.currentPage = page;

    if (this.currentPage > this.totalPages) {
      this.currentPage = 1;
      this.nextPage = 2;
      this.prevPage = 1;
    } else {
      this.nextPage = this.currentPage + 1;
      this.prevPage = this.currentPage - 1;
    }

    if (this.currentPage <= 0) {
      this.currentPage = this.totalPages;
      this.nextPage = this.currentPage + 1;
      this.prevPage = this.currentPage - 1;
    } else {
      this.nextPage = this.currentPage + 1;
      this.prevPage = this.currentPage - 1;
    }

    this.scrollToCurrentPage();
  }

  scrollToCurrentPage = () => {
    [...this.container.nativeElement.querySelectorAll(this.scrollableAreaSelector)].forEach(x => {
      x.scrollTo({
        top: 0,
        left: (this.currentPage - 1) * this.offsetWidth,
        behavior: 'smooth'
      });
    });
  }

  getPages = () => {
    return [...Array(this.totalPages).keys()];
  }

  getCurrentPage = () => {
    return this.currentPage;
  }
}
