<div class="dropdown" [ngClass]="{'dropdown-active': toggleDropdownStatus}">
  <div class="dropdown-selected-value"><input (click)="toggleDropdown($event);" (contextmenu)="$event.stopPropagation()" type="text" value="{{selectedItem[propertyName]}}" (input)="autocomplete($event)" placeholder="{{placeholder}}" #autocompleteField></div>
  @if (items.length || (showNewItem && this.newItem.name.length)) {
    <div class="dropdown-list" (click)="toggleDropdown($event);" (contextmenu)="$event.stopPropagation()">
      @for (item of items; track item.id) {
        <div class="dropdown-item" [hidden]="item.hasOwnProperty('visible') && !item.visible" [ngClass]="{'selected': selectedItem.id === item.id}" (click)="changeValue(item)">{{item.name}}</div>
      }

      @if (showNewItem) {
        <div class="dropdown-item" (click)="changeValue(newItem)">{{newItem.name}}</div>
      }
    </div>
  }
</div>
