@if (loading) {
  <div class="box">
    <div class="title">
      <h2>Edit note</h2>
      <div class="options">
        <div class="delete"><mat-icon class="material-icons-outlined" (click)="delete();">delete</mat-icon></div>
      </div>
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit(form)">
      <div class="form-group">
<!--        <label for="text">Note</label>-->
        <textarea formControlName="text" id="text" name="text" placeholder="Enter note"></textarea>
      </div>
      <div class="form-group">
        <button [disabled]="form.invalid">Save</button>
      </div>
    </form>
  </div>
}
