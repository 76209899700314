import {
  AfterViewInit,
  Component,
  DestroyRef,
  HostListener,
  NgZone,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels, ApexFill, ApexGrid, ApexMarkers, ApexPlotOptions, ApexResponsive, ApexStroke,
  ApexTitleSubtitle, ApexTooltip,
  ApexXAxis,
  NgApexchartsModule
} from "ng-apexcharts";
import {TransactionsService} from "../../services/transactions.service";
import {CurrencyPipe, DatePipe, DecimalPipe, NgClass} from "@angular/common";
import {
  MonthlySpentComponent
} from "../../components/monthly-spent/monthly-spent.component";
import {MatIcon} from "@angular/material/icon";
import {BehaviorSubject, Observable} from "rxjs";
import {AuthService} from "../../services/auth.service";
import {ListTransactionsComponent} from "../../components/list-transactions/list-transactions.component";
import {Dialog, DialogModule} from "@angular/cdk/dialog";
import {FixCurrencyPipe} from "../../pipes/fix-currency.pipe";
import Carousel from "../../utils/carousel";
import {MonthlyIncomeComponent} from "../../components/monthly-income/monthly-income.component";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {YearSummarizeComponent} from "../../components/year-summarize/year-summarize.component";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  plotOptions: ApexPlotOptions;
  markers: ApexMarkers;
  labels: any;
  tooltip: ApexTooltip;
  responsive: ApexResponsive
};

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    NgApexchartsModule,
    DecimalPipe,
    CurrencyPipe,
    MonthlySpentComponent,
    DatePipe,
    MatIcon,
    DialogModule,
    FixCurrencyPipe,
    NgClass,
    MonthlyIncomeComponent,
    YearSummarizeComponent
  ],
  providers: [
    CurrencyPipe,
    FixCurrencyPipe
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent implements OnInit, AfterViewInit {
  title: string = 'Dashboard';
  chartOptions: Partial<ChartOptions> | any;
  defaultChartOptions: {} =  {
    series: [],
    chart: {
      // animations: {
      //   enabled: false
      // },
      type: "area",
      width: '100%',
      height: '99px',
      sparkline: {
        enabled: true
      }
    },
    stroke: {
      curve: "straight"
    },
    xaxis: {
      categories: []
    },
    fill: {
      // type: 'solid'
    },
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topRight'
      },
      x: {
        show: true,
      },
      y: {
        title: {
          formatter: function (seriesName:any) {
            return '';
          }
        }
      },
      marker: {
        show: true
      },
      theme: 'dark'
    },
    responsive: []
  };

  // transactionsIncome: Partial<ChartOptions> | any;
  transactionsSpent: Partial<ChartOptions> | any;
  totalsChart: Partial<ChartOptions> | any;
  data: any = {
    total_income: 0,
    total_spent: 0,
    total_available: 0,
    total_budget: 0,
    total_budget_left: 0,
    total_over_budget: 0,
    total_over_budget_expectation: 0
  };

  date: any = new BehaviorSubject<any>(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  date$: Observable<any> = this.date.asObservable();
  dateAsString: any = new Date().toLocaleDateString('en-US', {month: 'long', year: "numeric"});
  user: any;
  disabledForwardButton: boolean = true;
  disabledBackwardButton: boolean = false;
  carousel: Carousel;
  clickOnDate: BehaviorSubject<any> = new BehaviorSubject<any>({});
  clickOnDate$: Observable<any> = this.clickOnDate.asObservable();

  @ViewChild('cards') cardsElement: any;

  constructor(
    private transactionsService: TransactionsService,
    private authService: AuthService,
    private dialog: Dialog,
    private destroyRef: DestroyRef,
    private ngZone: NgZone,
    private currency: CurrencyPipe,
    private fixCurrency: FixCurrencyPipe,
  ) {
    this.chartOptions = this.defaultChartOptions;
    this.user = this.authService.user;
    this.carousel = new Carousel;
  }

  ngOnInit(): void {
    this.date$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((r:any) => {
      const date = r;
      this.dateAsString = date.toLocaleDateString('en-US', {month: 'long', year: "numeric"});
      this.disabledForwardButton = (new Date().getMonth() === date.getMonth() && new Date().getFullYear() === date.getFullYear());
      this.fetchStatistics(date.getFullYear(), date.getMonth());
    });

    this.clickOnDate$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((item:any) => {
      if (item.hasOwnProperty('type') && item.hasOwnProperty('selectedDate')) {
        this.ngZone.run(() => {
          this.showTransactionForExactDay(item.selectedDate, item.type)
        });
      }
    });
  }

  ngAfterViewInit() {
    this.carousel.addContainer(this.cardsElement);
    this.carousel.calculate();
  }

  fetchStatistics(year: number, month: number): void {
    this.transactionsService.getStatistics(year, month).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(r => {
      if (!r.error) {
        let that = this;
        this.transactionsSpent = JSON.parse(JSON.stringify({...this.chartOptions,...this.defaultChartOptions}));
        this.transactionsSpent.chart.events = {
          markerClick: function (event: any, chartContext: any, config: any) {
            if (config.w.config.series[config.seriesIndex].data[config.dataPointIndex]) {
              that.clickOnDate.next({
                type: 'spent',
                selectedDate: new Date(config.w.globals.categoryLabels[config.dataPointIndex])
              });
            }
          }
        };

        this.transactionsSpent.series.push({
          name: "Spent",
          data: [0, ...r.data.statistics.transactions_spent.amount],
          // data: r.data.statistics.transactions_spent.amount,
          color: '#fa6464'
        });

        this.transactionsSpent.fill.type = 'gradient';
        this.transactionsSpent.fill.gradient = {};
        this.transactionsSpent.fill.gradient.gradientToColors = ['#363945'];
        this.transactionsSpent.fill.gradient.type = 'vertical';
        this.transactionsSpent.stroke.width = 1;
        this.transactionsSpent.xaxis.categories = ['', ...r.data.statistics.transactions_spent.label];
        this.transactionsSpent.tooltip.y = {
          formatter: (value:any) => {
            return that.fixCurrency.transform(that.currency.transform(value, that.user.currency, 'symbol-narrow'));
          }
        };


        // this.transactionsIncome = JSON.parse(JSON.stringify({...this.chartOptions,...this.defaultChartOptions}));
        // this.transactionsIncome.chart.events = {
        //   markerClick: function (event: any, chartContext: any, config: any) {
        //     if (config.w.config.series[config.seriesIndex].data[config.dataPointIndex]) {
        //       that.clickOnDate.next({
        //         type: 'income',
        //         selectedDate: new Date(config.w.globals.categoryLabels[config.dataPointIndex])
        //       });
        //     }
        //   }
        // };
        //
        // this.transactionsIncome.series.push({
        //   name: "Income",
        //   data: [0, ...r.data.statistics.transactions_income.amount, 0],
        //   color: '#1bc47d'
        // });
        //
        // this.transactionsIncome.stroke.width = 0;
        // this.transactionsIncome.xaxis.categories = ['', ...r.data.statistics.transactions_income.label, ''];

        this.data.total_income = r.data.statistics.total_income;
        this.data.total_spent = r.data.statistics.total_spent;
        this.data.total_available = r.data.statistics.total_available;
        this.data.total_budget = r.data.statistics.total_budget;
        let left = (r.data.statistics.total_budget - r.data.statistics.total_spent);
        this.data.total_budget_left = (left > 0 ? left : 0);
        this.data.total_over_budget = r.data.statistics.total_over_budget;
        this.data.total_over_budget_expectation = r.data.statistics.total_over_budget_expectation;

        this.totalsChart = JSON.parse(JSON.stringify({...this.chartOptions,...this.defaultChartOptions}));
        this.totalsChart.chart.type = 'bar';

        this.totalsChart.plotOptions = {
          bar: {
            dataLabels: {
              enabled: true,
              position: 'bottom'
            }
          }
        };

        this.totalsChart.dataLabels = {
          enabled: true,
          offsetY: 15,
          dropShadow: {
            enabled: true
          },
          // @ts-ignore
          formatter: function(value, { seriesIndex, dataPointIndex, w }) {
            return [w.config.series[seriesIndex].data[dataPointIndex].x, that.fixCurrency.transform(that.currency.transform(value, that.user.currency, 'symbol-narrow'))];
          }
        };

        this.totalsChart.tooltip.enabled = false;

        // @ts-ignore
        this.totalsChart.fill.colors = [function({ value, seriesIndex, dataPointIndex, w }) {
          if(dataPointIndex === 0) {
            return "#1bc47d";
          } else if (dataPointIndex === 1) {
            return '#A4A9BA';
          } else if (dataPointIndex === 2) {
            return '#fa6464';
          } else if (dataPointIndex === 3) {
            return '#FFBF00';
          } else if (dataPointIndex === 4) {
            return '#ff752d';
          } else if (dataPointIndex === 5) {
            return '#ff752d';
          } else {
            return '#2374E1';
          }
        }];

        this.totalsChart.series.push({
          data: [
            {x: 'Incomes', y: this.data.total_income},
            {x: 'Budget', y: this.data.total_budget},
            {x: 'Spent', y: this.data.total_spent},
            {x: 'Left', y: this.data.total_budget_left},
            {x: 'Over', y: this.data.total_over_budget},
            {x: 'Expect', y: this.data.total_over_budget_expectation},
            {x: 'Available', y: this.data.total_available},
          ]
        });

        this.totalsChart.responsive = [{
          breakpoint: 400,
          options: {
            dataLabels: {
              style: {
                fontSize: "10px"
              },
              formatter: (value:any, opt:any) => {
                return [opt.w.globals.labels[opt.dataPointIndex], that.fixCurrency.transform(that.currency.transform(value, that.user.currency, 'symbol-narrow'))]
              },
            },
          }
        }];
      }
    });
  }

  showTransactionForExactDay(selectedDate: Date, type: string): void {
    this.transactionsService.list(selectedDate.getFullYear(), selectedDate.getMonth(), [], selectedDate.getDate(), type).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((r: any) => {
      if (!r.error) {

        const dialogRef = this.dialog.open(ListTransactionsComponent, {
          panelClass: 'bsense-dialog'
        });

        dialogRef.componentInstance!.modal = true;
        dialogRef.componentInstance!.modalRef = dialogRef;
        r.data.transactions.forEach((x:any) => {
          dialogRef.componentInstance!.transactions.push(x);
        });
      }
    });
  }

  previousStatsPage(): void {
    this.carousel.gotoPreviousPage();
  }

  nextStatsPage(): void {
    this.carousel.gotoNextPage();
  }

  carouselPage(page: number): void {
    this.carousel.gotoPage(page);
  }

  previousMonth(): void {
    // if (!this.disabledBackwardButton) {
      let currentDate = this.date.getValue();
      currentDate.setMonth(currentDate.getMonth() - 1);
      this.date.next(currentDate);
    // }
  }

  nextMonth(): void {
    // if (!this.disabledForwardButton) {
      let currentDate = this.date.getValue();
      currentDate.setMonth(currentDate.getMonth() + 1);
      this.date.next(currentDate);
    // }
  }

  @HostListener('window:resize') resize() {
    this.carousel.calculate();
  }
}
