import {AfterViewInit, Component, DestroyRef, EventEmitter, HostListener, OnInit, ViewChild} from '@angular/core';
import {CustomAutocompleteComponent} from "../../components/custom-autocomplete/custom-autocomplete.component";
import {MatIcon} from "@angular/material/icon";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {CurrencyPipe, NgClass, TitleCasePipe} from "@angular/common";
import {Categories} from "../../models/Categories";
import {FieldsByCategories} from "../../models/FieldsByCategories";
import {FieldsService} from "../../services/fields.service";
import {AuthService} from "../../services/auth.service";
import Carousel from "../../utils/carousel";
import {FixCurrencyPipe} from "../../pipes/fix-currency.pipe";
import {Dialog, DialogModule} from "@angular/cdk/dialog";
import {EditBudgetComponent} from "../../components/edit-budget/edit-budget.component";
import {MatTooltip} from "@angular/material/tooltip";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {ListTransactionsComponent} from "../../components/list-transactions/list-transactions.component";
import {TransactionsService} from "../../services/transactions.service";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
  selector: 'app-fields-overview',
  standalone: true,
  imports: [
    CustomAutocompleteComponent,
    MatIcon,
    RouterLink,
    TitleCasePipe,
    NgClass,
    CurrencyPipe,
    FixCurrencyPipe,
    DialogModule,
    MatTooltip,
    MatProgressSpinner
  ],
  providers: [
    CurrencyPipe,
    FixCurrencyPipe
  ],
  templateUrl: './fields-overview.component.html',
  styleUrl: './fields-overview.component.css'
})
export class FieldsOverviewComponent implements OnInit, AfterViewInit {
  title: string = 'Fields';
  categories: Categories[] = [];
  fields: FieldsByCategories[] = [];
  type: string = '';
  months: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  user: any;
  flashMessage: EventEmitter<{message:string, type:string}> = new EventEmitter<{message: string; type: string}>();
  carousel: Carousel;
  year: number = new Date().getFullYear();
  month: string = (new Date().getMonth() + 1).toString().padStart(2, '0');
  loading: boolean = true;

  constructor(
    private fieldsService : FieldsService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private dialog: Dialog,
    private currency: CurrencyPipe,
    private fixCurrency: FixCurrencyPipe,
    private transactionsService: TransactionsService,
    private destroyRef: DestroyRef
  ) {
    this.user = this.authService.user;
    this.carousel = new Carousel();
  }

  ngOnInit() {
    this.route.url.subscribe(params => {
      if (params.length) {
        if (!['income', 'spent'].includes(params[0].path)) {
          this.router.navigate(['/fields-overview']);
        } else {
          this.type = params[0].path;

          this.fieldsService.getGroupedByCategoriesForOverview(this.type).subscribe(r => {
            this.fields = r.data.fields;
            this.loading = false;
          });
        }
      } else {
        this.router.navigate(['/fields-overview']);
      }
    });

    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        let container = document.getElementById('layout-container') as HTMLElement;

        if (container) {

          let interval = setInterval(() => {
            let el = container.querySelector('#' + fragment);
            if (el !== null) {
              container.scrollTo({
                top: (el.getBoundingClientRect().y - (window.innerHeight / 2)),
                behavior: 'smooth'
              });

              el.classList.add('highlighted');

              clearInterval(interval);
            }
          }, 100);
        }
      }
    });
  }

  @ViewChild('holdingContainer') holdingContainerElement: any;

  ngAfterViewInit() {
    let interval = setInterval(() => {
      if (this.fields.length) {
        this.carousel.addContainer(this.holdingContainerElement, '.table-group-cell-scrollable', '.table-group-cell:first-child');
        this.carousel.calculate();
        clearInterval(interval);
      }
    }, 100);
  }

  previousStatsPage(): void {
    this.carousel.gotoPreviousPage();
  }

  nextStatsPage(): void {
    this.carousel.gotoNextPage();
  }

  carouselPage(page: number): void {
    this.carousel.gotoPage(page);
  }

  getBudget(field: any, month: number): number | string {
    let output = '';
    let period = month.toString().padStart(2, '0') + '-' + this.year;

    if (period in field.budget_history && field.active_period_history[this.year].value.split(',').filter((x: any) => parseInt(x) === parseInt(period)).length) {
      output = field.budget_history[period].value;
    }

    return output;
  }

  getGroupTotal(item: any, month: number): number | string {
    let sum = 0;
    let period = month.toString().padStart(2, '0') + '-' + this.year;

    for (let i in item.fields) {
      if (period in item.fields[i].budget_history && item.fields[i].active_period_history[this.year].value.split(',').filter((x: any) => parseInt(x) === parseInt(period)).length) {
        sum += item.fields[i].budget_history[period].value;
      }
    }

    return sum || '';
  }

  getTotal(month: number): number | string {
    let sum = 0;
    let period = month.toString().padStart(2, '0') + '-' + this.year;

    for (let idx in this.fields) {
      for (let i in this.fields[idx].fields) {
        if (
          period in this.fields[idx].fields[i].budget_history &&
          this.fields[idx].fields[i].budget_history[period].value !== null &&
          this.fields[idx].fields[i].active_period_history[this.year].value.split(',').filter((x: any) => parseInt(x) === parseInt(period)).length
        ) {
          sum += this.fields[idx].fields[i].budget_history[period].value;
        }
      }
    }

    return sum || '';
  }

  compareAmountToBudget(item: any, month: number): string {
    let className = '';
    let period = month.toString().padStart(2, '0') + '-' + this.year;

    if (!item.hasOwnProperty('fields')) {
      className = this.compareAmountToBudgetForField(item, period);
    } else {
      className = this.compareAmountToBudgetForGroup(item, period);
    }

    return className;
  }

  /**
   *
   * @param field
   * @param period
   * @private
   */
  private compareAmountToBudgetForGroup(item: any, period: string): string {
    let budget = 0;
    let amounts = 0;
    let className = '';

    for (let field of item.fields) {
      if (period in field.budget_history) {
        budget += field.budget_history[period].value;
      }

      if (field.transactions) {
        if (field.transactions && period in field.transactions) {
          amounts += field.transactions[period];
        }
      }
    }

    if (amounts) {
      if (amounts < budget) {
        className = (this.type === 'spent' ? 'positive' : 'negative');
      } else if (amounts > budget) {
        className = (this.type === 'spent' ? 'negative' : 'positive');
      } else {
        className = 'neutral';
      }
    }

    return className;
  }

  /**
   *
   * @param field
   * @param period
   * @private
   */
  private compareAmountToBudgetForField(field: any, period: string): string {
    let budget = field.budget;
    let className = '';

    if (period in field.budget_history) {
      budget = field.budget_history[period].value;
    }

    if (field.transactions) {
      if (field.transactions && period in field.transactions) {
        if (field.transactions[period] < budget) {
          className = (field.type === 'spent' ? 'positive' : 'negative');
        } else if (field.transactions[period] > budget) {
          className = (field.type === 'spent' ? 'negative' : 'positive');
        }
      }
    }

    return className;
  }

  diffStatsForField(field: any, month: number): string {
    let period: string = month.toString().padStart(2, '0') + '-' + this.year;
    let budget = field.budget;
    let value: number = 0;
    let message: string = '';
    let type = 1;

    if (period in field.budget_history) {
      budget = field.budget_history[period].value;
    }

    if (field.transactions) {
      if (field.transactions && period in field.transactions) {
        if (field.transactions[period] < budget) {
          value = budget - field.transactions[period];
          type = 1;
        } else if (field.transactions[period] > budget) {
          value = field.transactions[period] - budget;
          type = 2;
        }
      }
    }

    if (value) {
      message = this.fixCurrency.transform(this.currency.transform(value, this.user.currency, 'symbol-narrow')) + ' ';

      if (this.type === 'spent') {
        message = (type === 2 ? 'Over with' : 'Under with') + ' ' + message;
      } else {
        message = (type === 1 ? 'Under with' : 'Over with') + ' ' + message;
      }
    }

    return message;
  }

  diffStatsForGroup(item: any, month: number): string {
    let period: string = month.toString().padStart(2, '0') + '-' + this.year;
    let budget: number = 0;
    let amounts: number = 0;
    let value: number = 0;
    let message: string = '';
    let type = 1;

    for (let field of item.fields) {
      if (this.isEnabled(field, month)) {
        if (period in field.budget_history) {
          budget += field.budget_history[period].value;
        }

        if (field.transactions) {
          if (field.transactions && period in field.transactions) {
            amounts += field.transactions[period];
          }
        }
      }
    }

    if (amounts) {
      if (amounts < budget) {
        value = budget - amounts;
        type = 1;
      } else if (amounts > budget) {
        value = amounts - budget;
        type = 2;
      }

      if (value) {
        message = this.fixCurrency.transform(this.currency.transform(value, this.user.currency, 'symbol-narrow')) + '';

        if (this.type === 'spent') {
          message = (type === 2 ? 'Over with' : 'Under with') + ' ' + message;
        } else {
          message = (type === 1 ? 'Under with' : 'Over with') + ' ' + message;
        }
      }
    }


    return message;
  }

  getTooltipStats(item: any, month: number): string {
    let message = '';

    if (!item.hasOwnProperty('fields')) {
      if (this.isEnabled(item, month)) {
        message = this.diffStatsForField(item, month);
      }
    } else {
      message = this.diffStatsForGroup(item, month);
    }

    return message;
  }

  openForEdit(event: any, field: any, month: number, category: string | null): void {
    if (event.target.closest('.table-group-cell').classList.contains('editable')) {
      const dialogRef = this.dialog.open(EditBudgetComponent, {
        panelClass: 'bsense-dialog',
      });

      dialogRef.componentInstance!.modalRef = dialogRef;
      dialogRef.componentInstance!.field = field;
      dialogRef.componentInstance!.month = month.toString().padStart(2, '0');
      dialogRef.componentInstance!.year = this.year;
      dialogRef.componentInstance!.categoryName = category;
    }
  }

  canEdit(month: number): boolean {
    let canEdit = false;
    let date = new Date();

    if (this.year >= date.getFullYear() && month >= (date.getMonth() + 1)) {
      canEdit = true;
    }

    return canEdit;
  }

  isEnabled(field: any, month: number): boolean {
    let isEnabled = false;
    let period = month.toString().padStart(2, '0') + '-' + this.year;

    if (field.active_period_history[this.year].value.split(',').filter((x: any) => parseInt(x) === parseInt(period)).length) {
      isEnabled = true;
    }

    return isEnabled;
  }

  hasAnyTransactions(item: any, month: number): boolean {
    let hasTransactions = false;
    let period: string = month.toString().padStart(2, '0') + '-' + this.year;

    if (!item.hasOwnProperty('fields')) {
      if (item.transactions && period in item.transactions) {
        hasTransactions = true;
      }
    } else {
      for (let field of item.fields) {
        if (field.transactions && period in field.transactions) {
          hasTransactions = true;
          break;
        }
      }
    }

    return hasTransactions;
  }

  showTransactions(item: any, month: number): void {
    if (this.hasAnyTransactions(item, (month + 1))) {
      let fieldIds = [item.id];

      if (item.hasOwnProperty('fields')) {
        for(const field in item.fields) {
          fieldIds.push(item.fields[field].id);
        }
      }

      this.transactionsService.list(this.year, month, fieldIds).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((r: any) => {
        if (!r.error) {
          const dialogRef = this.dialog.open(ListTransactionsComponent, {
            panelClass: 'bsense-dialog',
          });

          dialogRef.componentInstance!.modal = true;
          dialogRef.componentInstance!.modalRef = dialogRef;

          r.data.transactions.forEach((x:any) => {
            dialogRef.componentInstance?.transactions.push(x)
          });
        }
      });
    }
  }

  toggleCollapseExpandAll(event: any): void {
    let box = event.target.closest('.box');
    let groups = box.querySelectorAll('.category-name');
    let totalGroups = groups.length;
    let totalCollapsedGroups = box.querySelectorAll('.category-name.collapse').length;
    let totalExpandedGroups = box.querySelectorAll('.category-name.expand').length;

    if (!totalCollapsedGroups && !totalExpandedGroups) { // default state
      groups.forEach((group: any) => group.classList.add('collapse'));
    } else if (totalCollapsedGroups === totalGroups) { // all are collapsed
      groups.forEach((group: any) => {
        group.classList.remove('collapse');
        group.classList.add('expand');
      });
    } else if (totalExpandedGroups === totalGroups) { // all are expanded
      groups.forEach((group: any) => {
        group.classList.remove('expand');
        group.classList.add('collapse');
      });
    } else {
      if (totalCollapsedGroups >= totalExpandedGroups) {
        groups.forEach((group: any) => {
          group.classList.remove('collapse', 'expand');
          group.classList.add('expand');
        });
      } else {
        groups.forEach((group: any) => {
          group.classList.remove('collapse', 'expand');
          group.classList.add('collapse');
        });
      }
    }
  }

  toggleCollapseExpand(event: any): void {
    if (event.target.closest('.category-name').classList.contains('collapse')) {
      event.target.closest('.category-name').classList.replace('collapse', 'expand');
    } else {
      event.target.closest('.category-name').classList.remove('expand');
      event.target.closest('.category-name').classList.add('collapse');
    }
  }

  @HostListener('window:resize') resize() {
    this.carousel.calculate();
  }
}
