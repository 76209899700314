import {Component, DestroyRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router, RouterLink, RouterLinkActive, RouterOutlet} from '@angular/router';
import {MatIcon} from "@angular/material/icon";
import {AsyncPipe, DatePipe, NgClass, NgStyle} from "@angular/common";
import {ContainerScrollService} from "../../shared/container-scroll.service";
import {MatTooltip} from "@angular/material/tooltip";
import {CheckForUpdatesComponent} from "../../components/check-for-updates/check-for-updates.component";
import {UtilsService} from "../../services/utils.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {MatProgressBar} from "@angular/material/progress-bar";
import {LoadingService} from "../../shared/loading.service";

@Component({
  selector: 'app-interface',
  standalone: true,
  imports: [RouterOutlet, MatIcon, RouterLink, RouterLinkActive, NgClass, DatePipe, MatTooltip, CheckForUpdatesComponent, NgStyle, MatProgressBar, AsyncPipe],
  templateUrl: './layout-interface.component.html',
  styleUrl: './layout-interface.component.css'
})
export class LayoutInterfaceComponent implements OnInit, OnDestroy {
  title: string;
  currentUrl: string = '';
  flashMessageTimeout: any;
  scrollToTopIsVisible: boolean = false;
  keepAliveInterval: any;

  constructor(
    private router: Router,
    private containerScrollService: ContainerScrollService,
    private utilsService: UtilsService,
    private destroyRef: DestroyRef,
    public loadingService: LoadingService
  ) {
    this.title = '';
  }

  ngOnInit() {
    this.currentUrl = this.router.url;

    this.keepAliveInterval = setInterval(() => {
      this.utilsService.keepAlive().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    }, 1000 * 60);
  }

  ngOnDestroy() {
    clearInterval(this.keepAliveInterval);
  }

  @ViewChild('scrollTop') scrollTopElement: any;

  @HostListener('scroll', ['$event']) containerScroll(event: any): void {
    this.containerScrollService.update(event);

    if (event.target.scrollTop) {
      this.scrollTopElement.nativeElement.classList.add('show');
      this.scrollToTopIsVisible = true;
    } else {
      this.scrollTopElement.nativeElement.classList.remove('show');
      this.scrollToTopIsVisible = false;
    }
  }

  scrollToTop(): void {
    // @ts-ignore
    document.querySelector('#layout-container').scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.scrollToTopIsVisible = false;
  }

  fullSizeContainer(): boolean
  {
    let isFullSizeContainer = false;
    let fullSizePages = ['/calendar'];

    if (fullSizePages.includes(this.currentUrl)) {
      isFullSizeContainer = true;
    }

    return isFullSizeContainer;
  }

  @ViewChild('flashMessage') flashMessageDiv:any;

  flashMessage(text:string, type:string = 'done'): void {
    if (this.flashMessageTimeout) {
      this.flashMessageDiv.nativeElement.classList.remove('visible');
      clearTimeout(this.flashMessageTimeout);
      this.flashMessageTimeout = undefined;
    }

    this.flashMessageDiv.nativeElement.querySelector('p').innerText = text;
    this.flashMessageDiv.nativeElement.classList.remove('error', 'done');
    this.flashMessageDiv.nativeElement.classList.add(type);
    this.flashMessageDiv.nativeElement.querySelector('mat-icon').innerText = type;
    this.flashMessageDiv.nativeElement.classList.toggle('visible');

    this.flashMessageTimeout = setTimeout(() => {
      this.flashMessageDiv.nativeElement.classList.toggle('visible');
    }, 2500);
  }

  onActivate(component: any) {
    this.utilsService.visitedPage(this.router.url).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();

    // @ts-ignore
    document.querySelector('#layout-container').scroll({
      top: 0,
      left: 0,
      behavior: 'instant'
    });

    this.currentUrl = this.router.url;
    this.title = ' | ' + component.title;

    if (component.hasOwnProperty('flashMessage')) {
      component.flashMessage.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((r: {message:string, type:string}) => this.flashMessage(r.message, r.type));
    }
  }
}
