import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {AuthService} from "./auth.service";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private http: HttpClient, private authService: AuthService) {

  }

  add(feedback: string): Observable<any> {
    return this.http.post<Observable<any>>(environment.backendUrl + '/users/' + this.authService.user.id + '/feedback', {feedback: feedback}, {withCredentials: true});
  }
}
