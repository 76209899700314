import {Component, EventEmitter, OnInit} from '@angular/core';
import {CustomSelectDropdownComponent} from "../../components/custom-select-dropdown/custom-select-dropdown.component";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {SelectDropdownComponent} from "../../components/select-dropdown/select-dropdown.component";
import {FieldsByCategories} from "../../models/FieldsByCategories";
import {FieldsService} from "../../services/fields.service";
import {TransactionsService} from "../../services/transactions.service";
import {ScheduledTransactions} from "../../models/ScheduledTransactions";
import {ScheduleTransactionService} from "../../services/schedule-transaction.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
  selector: 'app-schedule-transaction',
  standalone: true,
  imports: [
    CustomSelectDropdownComponent,
    FormsModule,
    ReactiveFormsModule,
    SelectDropdownComponent,
    MatProgressSpinner
  ],
  templateUrl: './schedule-transaction.component.html',
  styleUrl: './schedule-transaction.component.css'
})
export class ScheduleTransactionComponent {
  title: string = 'Schedule transaction';
  form!: FormGroup;
  fields: FieldsByCategories[] = [];
  loadedFieldsCategories: any = {};
  flashMessage: EventEmitter<{message:string, type:string}> = new EventEmitter<{message: string; type: string}>();
  frequencyTypes: {id: string, name: string}[] = [
    {id: 'once', name: 'Once'},
    {id: 'daily', name: 'Daily'},
    {id: 'weekly', name: 'Weekly'},
    {id: 'monthly', name: 'Monthly'}
  ];
  frequencyIntervals: any = {
    'once': [],
    'daily': [],
    'weekly': [
      {id: '1', name: 'Monday'},
      {id: '2', name: 'Tuesday'},
      {id: '3', name: 'Wednesday'},
      {id: '4', name: 'Thursday'},
      {id: '5', name: 'Friday'},
      {id: '6', name: 'Saturday'},
      {id: '7', name: 'Sunday'},
    ],
    'monthly': [
      {id: '1', name: '1'},
      {id: '2', name: '2'},
      {id: '3', name: '3'},
      {id: '4', name: '4'},
      {id: '5', name: '5'},
      {id: '6', name: '6'},
      {id: '7', name: '7'},
      {id: '8', name: '8'},
      {id: '9', name: '9'},
      {id: '10', name: '10'},
      {id: '11', name: '11'},
      {id: '12', name: '12'},
      {id: '13', name: '13'},
      {id: '14', name: '14'},
      {id: '15', name: '15'},
      {id: '16', name: '16'},
      {id: '17', name: '17'},
      {id: '18', name: '18'},
      {id: '19', name: '19'},
      {id: '20', name: '20'},
      {id: '21', name: '21'},
      {id: '22', name: '22'},
      {id: '23', name: '23'},
      {id: '24', name: '24'},
      {id: '25', name: '25'},
      {id: '26', name: '26'},
      {id: '27', name: '27'},
      {id: '28', name: '28'},
    ],
  };

  transaction: any = {
    type: 'spent',
    amount: '',
    field_id: '',
    comment: ''
  };

  loading: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private fieldsService: FieldsService,
    private transactionsService: TransactionsService,
    private scheduledTransactionsService: ScheduleTransactionService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.transactionsService.get(params['id']).subscribe((result: any) => {
          if (!result.error) {
            if (result.data !== null) {
              Object.assign(this.transaction, result.data);
            } else {
              this.flashMessage.emit({message: 'Selected transaction was not found', type: 'error'});
            }
          }

          this.generateForm();
          this.loading = false;
        });
      } else {
        this.generateForm();
        this.loading = false;
      }
    });
  }

  generateForm(): void {
    let date = new Date();
    let dateFormat = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0') + 'T' + String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0');

    this.form = this.formBuilder.group({
      initial_at: new FormControl(dateFormat, [Validators.required]),
      frequency_type: new FormControl('once', [Validators.required]),
      frequency_interval: new FormControl(null),
      exact_time: new FormControl(null),
      type: new FormControl(this.transaction.type ?? 'spent', [Validators.required]),
      amount: new FormControl(this.transaction.amount, [Validators.required, Validators.pattern("^[0-9]+(\.[0-9]{1,2})?$")]),
      field_id: new FormControl(this.transaction.field_id, [Validators.required]),
      comment: new FormControl(this.transaction.comment)
    });

    this.fieldsService.getGroupedByCategories(0, this.transaction.type ?? 'spent').subscribe(r => {
      this.fields = r.data.fields;
      this.loadedFieldsCategories[this.transaction.type ?? 'spent'] = r.data.fields;

      this.form.valueChanges.subscribe(data => {
        if (['income', 'spent'].includes(data.type) && !this.loadedFieldsCategories.hasOwnProperty(data.type)) {
          this.fieldsService.getGroupedByCategories(0, data.type).subscribe(r => {
            this.fields = r.data.fields;

            this.loadedFieldsCategories[data.type] = r.data.fields;
          });
        } else {
          this.fields = this.loadedFieldsCategories[data.type];
        }
      });

      this.frequencyType.valueChanges.subscribe(value => {
        console.log(value);
        if (value === 'once') {
          this.exactTime.clearValidators();
          this.frequencyInterval.clearValidators();
          this.frequencyInterval.reset(null);
          this.exactTime.reset(null);
        } else if (value === 'daily') {
          // this.exactTime.setValidators(Validators.required);
          // this.frequencyInterval.clearValidators();
          // this.frequencyInterval.reset(null);
        } else if (value === 'weekly' || value === 'monthly') {
          // this.exactTime.setValidators(Validators.required);
          // this.frequencyInterval.setValidators([Validators.required, Validators.pattern("^[0-9]{1,2}%")]);
        }

        // this.frequencyInterval.updateValueAndValidity();
        // this.exactTime.updateValueAndValidity();
      });
    });
  }


  activeType(prop: string): string {
    let value = 'spent';

    if (this.transaction.type !== null) {
      value = this.transaction.type;
    }

    return (prop !== 'id' ? value.charAt(0).toUpperCase() + value.slice(1) : value);
  }

  activeField(prop: string): string {
    let value: any = '';

    if (this.transaction.field_id) {
      if (prop === 'id') {
        value = this.transaction.field_id;
      } else {

        for (const category of this.fields) {
          // @ts-ignore
          for (const field of category.fields) {
            if (field.id === this.transaction.field_id) {
              value = field.name;
            }
          }
        }
      }
    }

    return value;
  }

  toggleDatePicker(event: any) {
    event.target.showPicker();
  }

  get frequencyType() {
    return this.form.controls['frequency_type'];
  }

  get frequencyInterval() {
    return this.form.controls['frequency_interval'];
  }

  get exactTime() {
    return this.form.controls['exact_time'];
  }

  get amount() {
    return this.form.controls['amount'];
  }

  get field() {
    return this.form.controls['field_id'];
  }

  get comment() {
    return this.form.controls['comment'];
  }

  get type() {
    return this.form.controls['type'];
  }

  onSubmit(form: FormGroup): void {
    if (this.form.valid) {
      const scheduleTransaction: ScheduledTransactions = {} as ScheduledTransactions;
      scheduleTransaction.frequency_type = form.value.frequency_type;
      scheduleTransaction.frequency_interval = form.value.frequency_interval;
      scheduleTransaction.initial_at = Math.ceil(new Date(form.value.initial_at.replace(/-/g, '/').replace('T', ' ')).getTime() / 1000);
      scheduleTransaction.exact_time = form.value.exact_time;
      scheduleTransaction.type = form.value.type;
      scheduleTransaction.amount = form.value.amount;
      scheduleTransaction.field_id = form.value.field_id;
      scheduleTransaction.comment = form.value.comment;
      scheduleTransaction.updated_at = 0;
      scheduleTransaction.created_at = Math.ceil(new Date().getTime() / 1000);

      this.scheduledTransactionsService.create(scheduleTransaction).subscribe((r:any) => {
        if (!r.error) {
          // this.router.navigate(['/scheduled-transactions']);
          this.flashMessage.emit({message: 'New scheduled transaction was created!', type: 'done'});
        } else {
          this.flashMessage.emit({message: 'Cannot create a new scheduled transaction at this moment.', type: 'error'});
        }
      });
    }
  }
}
