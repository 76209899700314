<app-check-for-updates></app-check-for-updates>
@if (loadingService.isLoading$ | async) {
  <div class="global_loader">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
}
<div id="layout">
  <div id="layout-navbar">
    <h2 routerLink="">
      <span title="Budget Sense">Budget $ense</span>
    </h2>
    <nav>
      <a routerLink="create-new" routerLinkActive="active" matTooltip="New"><mat-icon class="material-icons-outlined">add</mat-icon></a>
    </nav>
  </div>
  <div id="layout-container" (scroll)="containerScroll($event)">
    <div [ngClass]="{'centered-container': !fullSizeContainer()}">
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>
    <div id="scroll-to" #scrollTop (click)="scrollToTop()" matTooltipPosition="above" matTooltip="{{scrollToTopIsVisible ? 'Scroll to top' : '' }}" [ngStyle]="{'cursor': (scrollToTopIsVisible ? 'pointer': 'default')}">
      <mat-icon class="material-icons-outlined">navigation</mat-icon>
    </div>
  </div>
  <div id="layout-navigation">
    <nav>
      <a routerLink="dashboard" routerLinkActive="active" matTooltip="Dashboard"><mat-icon class="material-icons-outlined">dashboard</mat-icon></a>
      <a routerLink="calendar" routerLinkActive="active" matTooltip="Calendar"><mat-icon class="material-icons-outlined">calendar_month</mat-icon></a>
      <a routerLink="notes" routerLinkActive="active" matTooltip="Notes"><mat-icon class="material-icons-outlined">text_snippet</mat-icon></a>
      <a routerLink="fields" routerLinkActive="active" matTooltip="Fields"><mat-icon class="material-icons-outlined">category</mat-icon></a>
      <a routerLink="fields-overview" routerLinkActive="active" matTooltip="Fields Overview"><mat-icon class="material-icons-outlined">layers</mat-icon></a>
      <a routerLink="menu" routerLinkActive="active" matTooltip="Menu"><mat-icon class="material-icons-outlined">menu</mat-icon></a>
    </nav>
  </div>
</div>
<div id="flash-message" #flashMessage>
  <mat-icon class="material-icons-outlined">done</mat-icon>
  <p>dasdsadsa</p>
</div>
