<div class="box">
  <div class="title">
    <h2>Feedback</h2>
  </div>
  <p>We're happy that you decided to give your feedback to us</p>
  <form [formGroup]="form" (ngSubmit)="onSubmit(form)">
    <div class="form-group">
<!--      <label for="feedback">Feedback</label>-->
      <textarea formControlName="feedback" id="feedback" name="feedback" placeholder="Enter your feedback here"></textarea>
    </div>
    <div class="form-group">
      <button [disabled]="form.invalid">Send</button>
    </div>
  </form>
</div>
