<div class="box" [ngClass]="{'fullscreen': fullscreenMode}">
  <div class="title">
    <h2>Monthly Spent</h2>
    @if (data.length) {
      <div class="options">
        <div matTooltip="Collapse/Expand all" (click)="toggleCollapseExpandAll($event)">
          <mat-icon class="material-icons-outlined">height</mat-icon>
        </div>

        @if (!fullscreenMode) {
          <div (click)="toggleFullscreen();" matTooltip="Fullscreen">
            <mat-icon class="material-icons-outlined">fullscreen</mat-icon>
          </div>
        } @else {
          <div (click)="toggleFullscreen();" matTooltip="Exit fullscreen">
            <mat-icon class="material-icons-outlined">fullscreen_exit</mat-icon>
          </div>
        }
      </div>
    }
  </div>
  @if (data.length) {
    <div class="table-groups aggregated-table">
    <div class="table-group-row table-header">
      <div class="table-group-cell">Category</div>
      <div class="table-group-cell">Actual</div>
      <div class="table-group-cell">Budget</div>
      <div class="table-group-cell">Difference</div>
      <div class="table-group-cell percent-of-income">% of Income</div>
      <div class="table-group-cell transactions-chart">Transactions</div>
    </div>
    @for(item of data; track data.created_at) {
      <div class="table-groups">
        <div class="table-group-row group-header">
          <div class="table-group-cell category-name" matTooltip="Toggle" (click)="toggleCollapseExpand($event)" data-id="{{ item.name }}"><span>{{ item.name }}</span><span class="total">total</span></div>
          <div class="table-group-cell actual">{{ item.amount | currency: user.currency : 'symbol-narrow' | fixCurrency }}</div>
          <div class="table-group-cell budget" [routerLink]="['/fields', 'spent']" [fragment]="'field_' + item.id">{{ item.budget | currency: user.currency : 'symbol-narrow' | fixCurrency }}</div>
          <div class="table-group-cell difference" [routerLink]="['/fields-overview', 'spent']" [fragment]="'field_' + item.id" [ngClass]="{'negative': ((item.budget - item.amount) < 0), 'positive': ((item.budget - item.amount) > 0)}">
            @if (item.amount && item.budget) {
              {{ Math.abs(item.budget - item.amount) | currency: user.currency : 'symbol-narrow' | fixCurrency }}
            }
          </div>
          <div class="table-group-cell percent-of-income" [ngClass]="{'negative': (((item.amount / total_income) * 100) > 100)}">
            @if ((item.amount / total_income) * 100 > 0 && (item.amount / total_income) * 100 <= 100) {
              {{ item.amount / total_income | percent: '1.0-2' }}
              <span style="width: {{ item.amount / total_income | percent: '1.0-2' }};"></span>
            } @else if ((item.amount / total_income) * 100 > 100) {
              {{ item.amount / total_income | percent: '1.0-2' }}
              <span style="width: 100%;"></span>
            } @else {

            }

          </div>
          <div class="table-group-cell transactions-chart" [ngClass]="{'has-transactions': item.amount}" (click)="showTransactions(item);" matTooltip="{{getNumberOfTransactions(item)}}">
            @if (item.amount) {
              @defer (on viewport) {
                <apx-chart [series]="[{data: item.log, color: '#2374E1'}]"
                           [chart]="chartOptions.chart"
                           [xaxis]="chartOptions.xaxis"
                           [dataLabels]="chartOptions.dataLabels"
                           [grid]="chartOptions.grid"
                           [stroke]="chartOptions.stroke"
                           [title]="chartOptions.title"
                           [fill]="chartOptions.fill"
                           [plotOptions]="chartOptions.plotOptions"
                           [labels]="chartOptions.labels"
                           [tooltip]="chartOptions.tooltip"
                ></apx-chart>
              } @placeholder () {
                <div></div>
              }
            }
          </div>
        </div>
        @for(field of item.fields; track field.created_at) {
          <div class="table-group-row">
            <div class="table-group-cell">{{ field.category_name }}</div>
            <div class="table-group-cell actual">{{ field.transactions.amount | currency: user.currency : 'symbol-narrow' | fixCurrency}}</div>
            <div class="table-group-cell budget {{checkForChangeInBudget(field)}}" [routerLink]="['/fields', 'spent']" [fragment]="'field_' + field.id">
              @if (field.budget) {
                {{ field.budget | currency: user.currency : 'symbol-narrow' | fixCurrency }}
              }
            </div>
            <div class="table-group-cell difference" [routerLink]="['/fields-overview', 'spent']" [fragment]="'field_' + field.id" [ngClass]="{'negative': ((field.budget - field.transactions.amount) < 0), 'positive': ((field.budget - field.transactions.amount) > 0)}">
              @if (field.budget && field.transactions.amount) {
                {{ Math.abs(field.budget - field.transactions.amount) | currency: user.currency : 'symbol-narrow'  | fixCurrency }}
              }
            </div>
            <div class="table-group-cell percent-of-income" [ngClass]="{'negative': (((field.transactions.amount / total_income) * 100) > 100)}">
              @if ((field.transactions.amount / total_income) * 100 > 0) {
                {{ field.transactions.amount / total_income | percent: '1.0-2' }}
                <span style="width: {{ field.transactions.amount / total_income | percent: '1.0-2' }};"></span>
              } @else {

              }
            </div>
            <div class="table-group-cell transactions-chart" [ngClass]="{'has-transactions': field.transactions.amount}" (click)="showTransactions(field);" matTooltip="{{getNumberOfTransactions(field)}}">
              @if (field.transactions.amount) {
                @defer (on viewport){
                  <apx-chart [series]="[{data: field.transactions.log, color: '#2374E1'}]"
                             [chart]="chartOptions.chart"
                             [xaxis]="chartOptions.xaxis"
                             [dataLabels]="chartOptions.dataLabels"
                             [grid]="chartOptions.grid"
                             [stroke]="chartOptions.stroke"
                             [title]="chartOptions.title"
                             [fill]="chartOptions.fill"
                             [plotOptions]="chartOptions.plotOptions"
                             [labels]="chartOptions.labels"
                             [tooltip]="chartOptions.tooltip"
                  ></apx-chart>
                } @placeholder () {
                  <div></div>
                }
              }
            </div>
          </div>
        }
      </div>
    }
  </div>
  } @else {
    @if (loading) {
      <div class="loader">
        <mat-progress-spinner strokeWidth="2" mode="indeterminate"></mat-progress-spinner>
      </div>
    } @else {
      <app-no-records-screen
        [title]="'You don\'t have any fields.'"
        [description]="'Please configure your fields first and make sure they\'re active for this period.'"
        [icon]="'info'"
      ></app-no-records-screen>
    }
  }
</div>
