import {Component, EventEmitter, OnInit} from '@angular/core';
import {CustomSelectDropdownComponent} from "../../components/custom-select-dropdown/custom-select-dropdown.component";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {SelectDropdownComponent} from "../../components/select-dropdown/select-dropdown.component";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-account',
  standalone: true,
  imports: [
    CustomSelectDropdownComponent,
    FormsModule,
    ReactiveFormsModule,
    SelectDropdownComponent
  ],
  templateUrl: './account.component.html',
  styleUrl: './account.component.css'
})
export class AccountComponent implements OnInit {
  title: string = 'Account';
  form!: FormGroup;
  flashMessage: EventEmitter<{message:string, type:string}> = new EventEmitter<{message: string; type: string}>();

  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService
  ) {

  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: new FormControl(this.authService.user.email, [Validators.required]),
      display_name: new FormControl(this.authService.user.display_name, [Validators.required]),
      currency: new FormControl(this.authService.user.currency, [Validators.required]),
      timezone: new FormControl(this.authService.user.timezone, [Validators.required]),
    });
  }

  getTimezones(): any {
    let obj = [];
    let list = Intl.supportedValuesOf('timeZone');

    for (let timezone of list) {
      obj.push({id: timezone, name: timezone});
    }

    return obj;
  }

  getCurrencies(): any {
    let obj = [];
    let list = Intl.supportedValuesOf('currency');

    for (let currency of list) {
      obj.push(
        {
          id: currency,
          name: this.getCurrencyDetails(currency)
        }
      );
    }

    return obj;
  }

  getCurrencyDetails(currencyCode: string): any {
    return (Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: 'name'
    }).format(0) + ' ( ' + Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: 'narrowSymbol'
    }).format(0) + ' )').replace(/0(\.0+)?/g, '').replace(/\s\s\)/, ' )');
  }

  onSubmit(form: FormGroup): void {
    if (this.form.valid) {
      this.authService.updateUser(form.value).subscribe((r:any) => {
        if (!r.error) {
          this.authService.user.display_name = form.value.display_name;
          this.authService.user.currency = form.value.currency;
          this.authService.user.timezone = form.value.timezone;
          this.flashMessage.emit({message: 'Successful updated.', type: 'done'});
        } else {
          this.flashMessage.emit({message: 'There was an error while trying to edit your profile information.', type: 'error'});
        }
      });
    }
  }

  protected readonly Intl = Intl;
}
