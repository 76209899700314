import {Component, DestroyRef, OnInit, ViewChild} from '@angular/core';
import {CurrencyPipe, DatePipe, NgClass, NgStyle} from "@angular/common";
import {TransactionsService} from "../../services/transactions.service";
import {MatIcon} from "@angular/material/icon";
import {AuthService} from "../../services/auth.service";
import {NoRecordsScreenComponent} from "../../components/no-records-screen/no-records-screen.component";
import {DialogModule, Dialog} from "@angular/cdk/dialog";
import {ListTransactionsComponent} from "../../components/list-transactions/list-transactions.component";
import {FixCurrencyPipe} from "../../pipes/fix-currency.pipe";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {MatTooltip} from "@angular/material/tooltip";
import {SelectDropdownComponent} from "../../components/select-dropdown/select-dropdown.component";
import {KeywordsCloudComponent} from "../../components/keywords-cloud/keywords-cloud.component";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
  selector: 'app-calendar',
  standalone: true,
  imports: [
    NgClass,
    DatePipe,
    MatIcon,
    CurrencyPipe,
    NgStyle,
    NoRecordsScreenComponent,
    DialogModule,
    FixCurrencyPipe,
    MatTooltip,
    SelectDropdownComponent,
    MatProgressSpinner
  ],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.css'
})
export class CalendarComponent  implements OnInit {
  title: string = 'Calendar';
  date: Date = new Date();
  year: number = this.date.getFullYear();
  month: number = this.date.getMonth();
  day: number = this.date.getDate();
  calendar: any = [];
  events: any = {};
  user: any;
  loading: boolean = true;
  filtersActivated: boolean = false;
  filtersText: string = 'filter_alt';
  filtersSearchText: string = '';
  disabledForwardButton: boolean = (new Date().getMonth() === this.month && new Date().getFullYear() === this.year);
  keywords: any = [];

  constructor(
    private transactionsService: TransactionsService,
    private authService: AuthService,
    private dialog: Dialog,
    private destroyRef: DestroyRef
  ) {
    this.generateCalendar();
    this.user = this.authService.user;
  }
  ngOnInit() {
    this.fetchData();
  }

  generateCalendar(): void {
    let firstDayOfTheCurrentMonth = new Date(this.year, this.month, 1).getDay();
    let currentMonthDays = new Date(this.year, this.month + 1, 0).getDate();
    let dayIndex = 1;

    for (let z = 1; z <= 35; ++z) {
      if (z >= firstDayOfTheCurrentMonth && z < (currentMonthDays + firstDayOfTheCurrentMonth)) {
        this.calendar.push({
          day: dayIndex,
          namedDay: (new Date(this.year, this.month, dayIndex)).toLocaleDateString('en-US', {weekday: 'short'}).toUpperCase(),
          active: (new Date().getFullYear() === this.year && new Date().getMonth() === this.month && this.day === dayIndex)
        });
        ++dayIndex;
      } else {
        this.calendar.push(0);
      }
    }
  }

  fetchData(): void {
    this.loading = true;

    this.transactionsService.calendar(this.year, this.month).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(r => {
      if (!r.error) {

        if (r.data.transactions.length) {
          r.data.transactions.forEach((i:any) => {
            if (!this.events.hasOwnProperty(i.day)) {
              this.events[i.day] = new Array();
            }

            this.events[i.day].push(i);
          });

          for (let z in this.events) {
            this.events[z].sort((a:any, b:any) => a.created_at - b.created_at);

            if (this.events[z].length) {
              let spent: number = 0;
              let income: number = 0;

              this.events[z].filter((x:any) => {
                if (x.type === 'spent') {
                  spent += x.amount;
                } else {
                  income += x.amount;
                }
              });

              this.events[z].push(this.sumDailyTransactions(spent, income));
            }
          }

          this.generateKeywords();
        }
      }

      this.loading = false;

      // 5ms should be enough for rendering
      setTimeout(() => {
        this.scrollToToday();
      }, 5);


      if (this.filtersActivated) {
        this.search();
      }
    });
  }

  generateKeywords(): void {
    let keywordsItems: any = [];

    for(let i in this.events) {
      this.events[i].forEach((z: any) => {
        if (z.hasOwnProperty('comment')) {
          z.comment.split(',').forEach((k:any) => {
            let keyword = k.trim();
            let capitalizedKeyword = keyword.charAt(0).toUpperCase() + keyword.slice(1);

            if (keyword.length) {
              if (!keywordsItems.hasOwnProperty(capitalizedKeyword)) {
                keywordsItems[capitalizedKeyword] = 0;
              }

              ++keywordsItems[capitalizedKeyword];
            }
          });
        }
      });
    }

    this.keywords = Object.entries(keywordsItems).sort((a:any, b:any) => {
      if (a[1] > b[1]) {
        return -1;
      }

      return 1;
    });
  }

  openKeywordsCloud(): void {
    const dialogRef = this.dialog.open(KeywordsCloudComponent, {
      panelClass: 'bsense-dialog',
    });

    dialogRef.componentInstance!.list = this.keywords;
    dialogRef.componentInstance!.modalRef = dialogRef;

    dialogRef.closed.subscribe(r => {
      if (dialogRef.componentInstance?.selectedKeyword) {
        this.filtersSearchText = dialogRef.componentInstance?.selectedKeyword;
        this.search();
      }
    });
  }

  @ViewChild('calendarContainer') calendarElement: any;

  scrollToToday(): void
  {
    const container = this.calendarElement.nativeElement.closest('#layout-container');
    const today = this.calendarElement.nativeElement.querySelector('.today');

    if (today) {;
      container.scrollTo({
        top: today.getBoundingClientRect().y - (container.getBoundingClientRect().y + 70), // @TODO it's not okay with opened filters
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  viewTransaction(item: any): void {
    const dialogRef = this.dialog.open(ListTransactionsComponent, {
      panelClass: 'bsense-dialog',
    });

    dialogRef.componentInstance!.modal = true;
    dialogRef.componentInstance!.modalRef = dialogRef;
    dialogRef.componentInstance!.modalTitle = 'Selected transaction';
    dialogRef.componentInstance?.transactions.push(item);
  }

  getName(item: any): string {
    return item.parent_category_name + ' - ' +  item.category_name;
  }

  nextMonth(): void {
    // if (!this.disabledForwardButton) {
      this.date = new Date(this.year, this.month + 1);
      this.resetData();
      this.generateCalendar();
      this.fetchData();
    // }
  }

  previousMonth(): void {
    this.date = new Date(this.year, this.month - 1);
    this.resetData();
    this.generateCalendar();
    this.fetchData();
  }

  resetData(): void {
    this.year = this.date.getFullYear();
    this.month = this.date.getMonth();
    this.disabledForwardButton = (new Date().getMonth() === this.month && new Date().getFullYear() === this.year);
    this.calendar = [];
    this.events = {};
  }

  @ViewChild('filters') filterElement: any;

  toggleFilters(): void {
    if (!this.filterElement.nativeElement.classList.contains('show')) {
      this.filtersActivated = true;
      this.filtersText = 'filter_alt_off';
    } else {
      this.filtersActivated = false;
      this.filtersSearchText = '';
      this.filtersText = 'filter_alt';
      // reset
      this.search();
    }
  }

  clearFilters(): void {
    this.filtersSearchText = '';
    this.search();
  }

  searchEvent(event: any): void {
    this.filtersSearchText = event.target.value.trim();
    this.search();
  }

  search(): void {
    this.calendarElement.nativeElement.closest('#layout-container').scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    for (let day in this.events) {
      this.events[day].filter((x: any) => {
        if (x.hasOwnProperty('id')) {
          if (
            x.category_name.toLowerCase().includes(this.filtersSearchText.toLowerCase()) ||
            x.parent_category_name.toLowerCase().includes(this.filtersSearchText.toLowerCase()) ||
            x.comment.toLowerCase().includes(this.filtersSearchText.toLowerCase())
          ) {
            x.visible = true;
          } else {
            x.visible = false;
          }
        }
      });

      if (this.events[day].length) {
        let spent: number = 0;
        let income: number = 0;

        this.events[day].filter((x:any) => {
          if (x.visible) {
            if (x.type === 'spent') {
              spent += x.amount;
            } else {
              income += x.amount;
            }
          }
        });

        this.events[day][this.events[day].length - 1] = this.sumDailyTransactions(spent, income);
      }
    }
  }

  private sumDailyTransactions(spent: number, income: number): any {
    let spent_percent: number = 0;
    let income_percent: number = 0;

    if (spent === 0) {
      income_percent = 100;
    } else if (income === 0) {
      spent_percent = 100;
    } else if (income >= spent) {
      spent_percent = Math.ceil((spent / income) * 100);
      income_percent = 100 - spent_percent;
    } else if (spent >= income) {
      income_percent = Math.ceil((income / spent) * 100);
      spent_percent = 100 - income_percent;
    }

    return {
      spent: spent,
      income: income,
      spent_percent: spent_percent,
      income_percent: income_percent,
    };
  }

  protected readonly Object = Object;
}
