import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgClass} from "@angular/common";
import {CategoriesService} from "../../services/categories.service";
import {Categories} from "../../models/Categories";

@Component({
  selector: 'app-custom-autocomplete',
  standalone: true,
  imports: [
    NgClass,
  ],
  templateUrl: './custom-autocomplete.component.html',
  styleUrl: './custom-autocomplete.component.css'
})
export class CustomAutocompleteComponent implements OnInit {
  @Output() selectedItemChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() selectedItem: any;
  @Input() propertyName!: string;
  @Input() type!: string;
  @Input() items: any;
  @Input() placeholder!: string;
  showNewItem: boolean = false;
  newItem: Categories = {
    id: null,
    parent_category_id: null,
    name: ''
  } as Categories;
  toggleDropdownStatus: boolean = false;

  @ViewChild('autocompleteField') autocompleteField: any;

  constructor(private elementRef: ElementRef, private categoriesService: CategoriesService) {

  }

  ngOnInit() {
    if (typeof this.items === 'undefined') {
      this.items = [];
    } else {
      this.items.filter((x:any) => x.visible = true);
    }
  }

  autocomplete(value: any): void {
    let search = value.target.value;

    this.items.filter((x: any) => {
      if (x.name.includes(search) && x.id !== '') {
        x.visible = true;
      } else {
        x.visible = false;
      }
    });

    if (!this.items.filter((x: any) => x.visible).length) {
      this.showNewItem = true;
      this.newItem.name = search;
    } else {
      this.showNewItem = false;
    }
  }

  changeValue(item: any)
  {
    if (!item.hasOwnProperty('category_name')) {
      this.selectedItem.id = item.id
      this.selectedItem.name = item.name;
    } else {
      this.selectedItem.category_id = item.id
      this.selectedItem.category_name = item.name;
    }

    this.selectedItemChange.emit(this.selectedItem);
  }

  toggleDropdown(event: any){
    if (
      event.target.tagName === 'INPUT' ||
      event.target.classList.contains('dropdown-item') ||
      event.target.classList.contains('dropdown-selected-value')
    ) {
      this.toggleDropdownStatus = !this.toggleDropdownStatus;
    }
  }

  @HostListener('document:click', ['$event']) clickOutside(event: any): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.toggleDropdownStatus = false;
      this.autocompleteField.nativeElement.dispatchEvent(new Event('focusout'));
    }
  }

  @HostListener('document:keydown.escape', ['$event']) hitESCtoCloseDropdown(event: KeyboardEvent): void {
    this.toggleDropdownStatus = false;
    // @TODO this not work
    this.autocompleteField.nativeElement.dispatchEvent(new Event('focusout'));

  }

  @HostListener('document:keydown', ['$event']) hitDownButton(event: KeyboardEvent): void {
    if (event.key === 'ArrowDown') {

    }

    if (event.key === 'ArrowUp') {
      this.changeValue(this.items[0]);
    }
  }
}
