<div id="available-update" [ngClass]="{'visible': checkForUpdateService.isNewVersionAvailable}" (click)="applyUpdate()">
  <div id="available-box">
    <div class="box">
      <div class="icon positive">
        <mat-icon class="material-icons-outlined">update</mat-icon>
      </div>
      <div class="info">
        <div class="title">
          <h2>New version is available!</h2>
        </div>
        <p>Click here to update <span>Budget Sense</span></p>
      </div>
    </div>
  </div>
</div>


