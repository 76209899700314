import {Component} from '@angular/core';
import {DecimalPipe} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'app-keywords-cloud',
  standalone: true,
  imports: [
    DecimalPipe,
    MatIcon,
    MatTooltip
  ],
  templateUrl: './keywords-cloud.component.html',
  styleUrl: './keywords-cloud.component.css'
})
export class KeywordsCloudComponent {
  list: any = [];
  modalRef?: any;
  selectedKeyword!: string | null;

  selectKeyword(keyword: string): void {
    this.selectedKeyword = keyword;
    this.modalRef.close();
  }

  closeModal(): void {
    this.modalRef.close();
  }
}
