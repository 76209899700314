<label>{{name}}</label>
<div class="dropdown" (click)="toggleDropdown($event);" [ngClass]="{'dropdown-active': toggleDropdownStatus}">
  <div class="dropdown-selected-value">{{selectedItemName}}&nbsp;</div>
  <div class="dropdown-list">
    @for (field of items; track field.id) {
      <div class="dropdown-item dropdown-group">{{field.name}}</div>
      @for (item of field.fields; track item.id) {
        <div class="dropdown-item" [ngClass]="{'selected': item.id === selectedItemId}" (click)="changeValue(item)">{{item.name}}</div>
      }
    }
  </div>
</div>
