@if (scheduledTransactions.length) {
  <div class="box">
    <div class="title">
      <h2>
        Scheduled Transactions
      </h2>
    </div>
    <div class="table-groups scheduledTransactions-table">
      <div class="table-group-row table-header">
        <div class="table-group-cell">Category</div>
        <div class="table-group-cell">Amount</div>
        <div class="table-group-cell">Comment</div>
        <div class="table-group-cell">Type</div>
        <div class="table-group-cell">Last Start</div>
        <div class="table-group-cell">Next Start</div>
        <div class="table-group-cell">Active</div>
        <div class="table-group-cell actions"></div>
      </div>
      @for(item of scheduledTransactions; track scheduledTransactions.created_at) {
        <div class="table-group-row">
          <div class="table-group-cell">{{ item.parent_category_name }} - {{ item.category_name }}</div>
          <div class="table-group-cell amount {{item.type}}">{{ item.amount | currency: user.currency : 'symbol-narrow' | fixCurrency}}</div>
          <div class="table-group-cell" matTooltip="{{ item.comment }}">{{ item.comment }}</div>
          <div class="table-group-cell type">{{ item.frequency_type }}</div>
          <div class="table-group-cell center-content">
            @if (item.last_run_at) {
              {{ item.last_run_at * 1000 | date: 'd MMM, y HH:mm' }}
            }
          </div>
          <div class="table-group-cell center-content">
            @if (item.next_run_at) {
              {{ item.next_run_at * 1000 | date: 'd MMM, y HH:mm' }}
            }
          </div>
          <div class="table-group-cell center-content">
            @if (item.is_active) {
              Yes
            } @else {
              No
            }
          </div>
          <div class="table-group-cell actions">
            <div class="btn play" routerLink="/scheduled-transactions/{{item.id}}" matTooltip="Manually start now"></div>
            <div class="btn edit" routerLink="/scheduled-transactions/{{item.id}}" matTooltip="Edit"></div>
          </div>
        </div>
      }
    </div>
  </div>
} @else {
  @if (loading) {
    <div class="loader">
      <mat-progress-spinner strokeWidth="2" mode="indeterminate"></mat-progress-spinner>
    </div>
  } @else {
    <app-no-records-screen
      [title]="'You still don\'t have any scheduled transactions'"
      [description]="'To get your first scheduled transaction, first you must create it.'"
      [icon]="'schedule'"
    ></app-no-records-screen>
  }
}
