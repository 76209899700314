import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-select-dropdown',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './select-dropdown.component.html',
  styleUrl: './select-dropdown.component.css'
})
export class SelectDropdownComponent implements OnInit {
  @Input() name: string = '';
  @Input() items: any = [];
  @Input() key: any = '';
  @Input() form!: FormGroup;
  @Input() selectedId: any = '';
  @Input() selectedName: string = '';
  @Output() selectedItemEvent: EventEmitter<any> = new EventEmitter<any>();
  toggleDropdownStatus: boolean = false;

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit() {

  }

  changeValue(selectedItem: any)
  {
    this.selectedId = selectedItem.id;
    this.selectedName = selectedItem.name;

    if (typeof this.form !== 'undefined') {
      this.form.controls[this.key].setValue(this.selectedId);
    }

    this.selectedItemEvent.emit({'id': this.selectedId, 'name': this.selectedName});
  }

  toggleDropdown(event: any){
    if (
      !event.target.classList.contains('dropdown-group') &&
      (
        event.target.classList.contains('dropdown-item') ||
        event.target.classList.contains('dropdown-selected-value')
      )
    ) {
      this.toggleDropdownStatus = !this.toggleDropdownStatus;
    }
  }

  @HostListener('document:click', ['$event']) clickOutside(event: any): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.toggleDropdownStatus = false;
    }
  }

  @HostListener('document:keydown.escape', ['$event']) hitESCtoCloseDropdown(event: KeyboardEvent): void {
    this.toggleDropdownStatus = false;
  }
}
