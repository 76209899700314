import { Injectable } from '@angular/core';
import { SwUpdate } from "@angular/service-worker";
import {Subscription, interval} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CheckForUpdateService {
  isNewVersionAvailable: boolean = false;
  newVersionSubscription!: Subscription;

  constructor(
    private swUpdate: SwUpdate
  ) {
    this.checkForUpdate();
  }

  checkForUpdate(): void {
    this.newVersionSubscription?.unsubscribe();
    console.log('- initial check for update');

    if (this.swUpdate.isEnabled) {
      interval(1000 * 10).subscribe(async () => {
          console.log('- check for update now...');
          let isNewVersionAvailable = await this.swUpdate.checkForUpdate();
          if (!this.isNewVersionAvailable && isNewVersionAvailable) {
            console.log('- we have a new update... :) fafa');
            this.isNewVersionAvailable = true;
          }
      });
    }
  }

  applyUpdate(): void {
    this.swUpdate.activateUpdate().then(() => document.location.reload()).catch(error => console.log('Failed to update: ', error));
  }
}
