<div class="box">
  <div class="title">
    <h2>Schedule transaction</h2>
  </div>
  <p>Add new scheduled transaction...</p>
  @if (!loading) {
    <form [formGroup]="form" (ngSubmit)="onSubmit(form)">
    <div class="form-group">
      <app-select-dropdown [form]="form" [key]="'frequency_type'" [items]="frequencyTypes" [name]="'Frequency type'" [selectedName]="'Once'" [selectedId]="'once'"></app-select-dropdown>
    </div>
    @if (frequencyType.value === 'weekly' || frequencyType.value === 'monthly') {
      <div class="form-group">
        <app-select-dropdown [form]="form" [key]="'frequency_interval'" [items]="frequencyIntervals[frequencyType.value]" [name]="'Frequency interval'"></app-select-dropdown>
      </div>
    }
    <div class="form-group">
      @if (frequencyType.value === 'once') {
        <label for="initial_at">When</label>
        <input formControlName="initial_at" id="initial_at" name="initial_at" type="datetime-local" (click)="toggleDatePicker($event)">
      } @else if (frequencyType.value === 'daily') {
        <label for="initial_at">Active after</label>
        <input formControlName="initial_at" id="initial_at" name="initial_at" type="datetime-local" (click)="toggleDatePicker($event)">
      } @else {
        <label for="initial_at">Active after</label>
        <input formControlName="initial_at" id="initial_at" name="initial_at" type="datetime-local" (click)="toggleDatePicker($event)">
      }
    </div>
    @if (frequencyType.value !== 'once') {
      <div class="form-group">
          <label for="exact_time">Time</label>
          <input formControlName="exact_time" id="exact_time" name="exact_time" type="time">
      </div>
    }
    <div class="form-group">
      <app-select-dropdown [form]="form" [key]="'type'" [items]="[{'id': 'spent', 'name': 'Spent'},{'id': 'income', 'name': 'Income'}]" [name]="'Type'" [selectedName]="activeType('name')" [selectedId]="activeType('id')"></app-select-dropdown>
    </div>
    @if (type.value !== '') {
      <div class="form-group">
        <label for="amount">Amount</label>
        <input formControlName="amount" id="amount" name="amount" placeholder="0.00">
      </div>
      <div class="form-group">
        <app-custom-select-dropdown [form]="form" [key]="'field_id'" [items]="fields" [name]="'Category'" [selectedItemId]="activeField('id')" [selectedItemName]="activeField('name')"></app-custom-select-dropdown>
      </div>
      <div class="form-group">
        <label for="comment">Comment</label>
        <textarea formControlName="comment" id="comment" name="comment" placeholder="Enter comment"></textarea>
      </div>
    }
    <div class="form-group">
      <button [disabled]="form.invalid">Create</button>
    </div>
  </form>
  } @else {
    <div class="loader">
      <mat-progress-spinner strokeWidth="2" mode="indeterminate"></mat-progress-spinner>
    </div>
  }
</div>
