import {
  AfterViewInit,
  Component,
  DestroyRef,
  HostListener,
  Input,
  OnInit
} from '@angular/core';
import {TransactionsService} from "../../services/transactions.service";
import {DatePipe, DecimalPipe, PercentPipe, NgClass, CurrencyPipe} from "@angular/common";
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels, ApexFill, ApexGrid, ApexPlotOptions, ApexStroke,
  ApexTitleSubtitle, ApexTooltip,
  ApexXAxis,
  NgApexchartsModule
} from "ng-apexcharts";
import {AuthService} from "../../services/auth.service";
import {MatIcon} from "@angular/material/icon";
import {RouterLink} from "@angular/router";
import {DialogModule, Dialog} from "@angular/cdk/dialog";
import {ListTransactionsComponent} from "../list-transactions/list-transactions.component";
import {FixCurrencyPipe} from "../../pipes/fix-currency.pipe";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {MatTooltip} from "@angular/material/tooltip";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {NoRecordsScreenComponent} from "../no-records-screen/no-records-screen.component";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  plotOptions: ApexPlotOptions;
  labels: any;
  tooltip: ApexTooltip;
};

@Component({
  selector: 'app-monthly-spent',
  standalone: true,
  imports: [
    NgClass,
    NgApexchartsModule,
    DatePipe,
    DecimalPipe,
    PercentPipe,
    CurrencyPipe,
    MatIcon,
    RouterLink,
    DialogModule,
    FixCurrencyPipe,
    MatTooltip,
    MatProgressSpinner,
    NoRecordsScreenComponent
  ],
  templateUrl: './monthly-spent.component.html',
  styleUrl: './monthly-spent.component.css'
})
export class MonthlySpentComponent implements OnInit {
  data: any = [];
  user: any;
  chartOptions: Partial<ChartOptions> | any;
  @Input() total_income: any;
  @Input() date: any;
  currentYear!: number;
  currentMonth!: number;
  fullscreenMode: boolean = false;
  loading: boolean = true;

  constructor(
    private transactionsService: TransactionsService,
    private authService: AuthService,
    private dialog: Dialog,
    private destroyRef: DestroyRef
  ) {
    this.chartOptions = {
      series: [],
      chart: {
        type: "line",
        width: '100%',
        height: 30,
        // animations: {
        //   enabled: false
        // },
        sparkline: {
          enabled: true
        }
      },
      tooltip: {
        enabled: false,
        theme: false,
        fixed: {
          enabled: false
        },
        x: {
          show: true
        },
        y: {
          title: {
            formatter: (seriesName: any) => "Transactions"
          }
        },
        marker: {
          show: false
        }
      },
      stroke: {
        width: 2
      }
    };

    this.user = this.authService.user;
  }

  ngOnInit() {
    this.date.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((r:any) => {
      this.currentYear = r.getFullYear();
      this.currentMonth = r.getMonth();

      this.transactionsService.monthlySpentData(r.getFullYear(), r.getMonth()).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(r => {
        if (!r.error) {
          this.data = r.data.transactions;
          this.loading = false;
        }
      });
    })
  }

  toggleFullscreen(): void {
    this.fullscreenMode = !this.fullscreenMode;
  }

  toggleCollapseExpandAll(event: any): void {
    let box = event.target.closest('.box');
    let groups = box.querySelectorAll('.category-name');
    let totalGroups = groups.length;
    let totalCollapsedGroups = box.querySelectorAll('.category-name.collapse').length;
    let totalExpandedGroups = box.querySelectorAll('.category-name.expand').length;
    let totalDefaultGroups = box.querySelectorAll('.category-name:not(.collapse, .expand)').length;

    if (!totalCollapsedGroups && !totalExpandedGroups) { // default state
      groups.forEach((group: any) => group.classList.add('collapse'));
    } else if (totalCollapsedGroups === totalGroups) { // all are collapsed
      groups.forEach((group: any) => {
        group.classList.remove('collapse');
        group.classList.add('expand');
      });
    } else if (totalExpandedGroups === totalGroups) { // all are expanded
      groups.forEach((group: any) => {
        group.classList.remove('expand');
        group.classList.add('collapse');
      });
    } else {
      if (totalCollapsedGroups >= (totalExpandedGroups + totalDefaultGroups)) {
        groups.forEach((group: any) => {
          group.classList.remove('collapse', 'expand');
          group.classList.add('expand');
        });
      } else {
        groups.forEach((group: any) => {
          group.classList.remove('collapse', 'expand');
          group.classList.add('collapse');
        });
      }
    }
  }

  toggleCollapseExpand(event: any): void {
    if (event.target.closest('.category-name').classList.contains('collapse')) {
      event.target.closest('.category-name').classList.replace('collapse', 'expand');
    } else {
      event.target.closest('.category-name').classList.remove('expand');
      event.target.closest('.category-name').classList.add('collapse');
    }
  }

  @HostListener('document:keydown.escape', ['$event']) hitESCtoCloseDropdown(event: KeyboardEvent): void {
    if (this.fullscreenMode) {
      this.toggleFullscreen();
    }
  }

  checkForChangeInBudget(field: any): string {
    let className = '';
    let index = (this.currentMonth + 1).toString().padStart(2, '0') + '-' + this.currentYear;

    if (field.budget_changes_log !== null && field.budget_changes_log.hasOwnProperty(index)) {
      let latestChange = field.budget_changes_log[index][field.budget_changes_log[index].length - 1];
      className = (latestChange.old < latestChange.new ? 'increased' : 'decreased');
    }

    return className;
  }

  showTransactions(item: any): void {
    if (typeof item.amount !== 'undefined' && item.amount || typeof item.transactions !== 'undefined' && item.transactions.amount) {
      let fieldIds = [item.id];

      if (item.hasOwnProperty('fields')) {
        for(const field in item.fields) {
          fieldIds.push(item.fields[field].id);
        }
      }

      this.transactionsService.list(this.currentYear, this.currentMonth, fieldIds).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((r: any) => {
        if (!r.error) {
          const dialogRef = this.dialog.open(ListTransactionsComponent, {
            panelClass: 'bsense-dialog',
          });

          dialogRef.componentInstance!.modal = true;
          dialogRef.componentInstance!.modalRef = dialogRef;

          r.data.transactions.forEach((x:any) => {
            dialogRef.componentInstance?.transactions.push(x)
          });
        }
      });
    }
  }

  getNumberOfTransactions(item: any): string {
    let message = '';
    let transactions = 0;

    if (item.hasOwnProperty('fields')) {
      transactions = item.log.reduce((a:number, b:number) => a + b, 0);
    } else {
      transactions = item.transactions.log.reduce((a:number, b:number) => a + b, 0);
    }

    if (transactions) {
      if (transactions === 1) {
        message += 'Single transaction';
      } else {
        message += transactions + ' transactions';
      }
    }

    return message;
  }

  protected readonly Math = Math;
}
