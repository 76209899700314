import { Component } from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-create-new',
  standalone: true,
  imports: [
    MatIcon,
    RouterLink
  ],
  templateUrl: './create-new-list.component.html',
  styleUrl: './create-new-list.component.css'
})
export class CreateNewListComponent {
  title: string = 'Create new';
}
