<div class="box menu" routerLink="/account">
  <div class="icon">
    <mat-icon class="material-icons-outlined">person</mat-icon>
  </div>
  <div class="info">
    <div class="title">
      <h2>Your account</h2>
    </div>
    <p>You can configure your account settings from here.</p>
  </div>
</div>
<div class="box menu" routerLink="/transactions">
  <div class="icon">
    <mat-icon class="material-icons-outlined">account_balance</mat-icon>
  </div>
  <div class="info">
    <div class="title">
      <h2>Transactions</h2>
    </div>
    <p>You can schedule a transaction for the future or to repeat on X period</p>
  </div>
</div>
<div class="box menu" routerLink="/scheduled-transactions">
  <div class="icon">
    <mat-icon class="material-icons-outlined">schedule</mat-icon>
  </div>
  <div class="info">
    <div class="title">
      <h2>Yours scheduled transactions</h2>
    </div>
    <p>You can schedule a transaction for the future or to repeat on X period</p>
  </div>
</div>
<div class="box menu" routerLink="/feedback">
  <div class="icon">
    <mat-icon class="material-icons-outlined">feedback</mat-icon>
  </div>
  <div class="info">
    <div class="title">
      <h2>Feedback</h2>
      <p>You have it, we're listening! Any kind of feedback or any suggestions are well appreciated!</p>
    </div>
  </div>
</div>
<div class="box menu" routerLink="/sign-out">
  <div class="icon">
    <mat-icon class="material-icons-outlined">power_settings_new</mat-icon>
  </div>
  <div class="info">
    <div class="title">
      <h2>Sign out</h2>
      <p>It was a pleasure to have you. Hopes to see you soon!</p>
    </div>
  </div>
</div>
