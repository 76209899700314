<div class="box">
  <div class="title">
    <h2>New transaction</h2>
  </div>
  <p>Add new transaction...</p>
  <form [formGroup]="form" (ngSubmit)="onSubmit(form)">
    <div class="form-group">
      <label for="created_at">When</label>
      <input formControlName="created_at" id="created_at" name="created_at" type="datetime-local" (click)="toggleDatePicker($event)">
    </div>
    <div class="form-group">
      <app-select-dropdown [form]="form" [key]="'type'" [items]="[{'id': 'spent', 'name': 'Spent'},{'id': 'income', 'name': 'Income'}]" [name]="'Type'" [selectedName]="'Spent'" [selectedId]="'spent'"></app-select-dropdown>
    </div>
    @if (type.value !== '') {
      <div class="form-group">
        <label for="amount">Amount</label>
        <input formControlName="amount" id="amount" name="amount" placeholder="0.00">
      </div>
      <div class="form-group">
        <app-custom-select-dropdown [form]="form" [key]="'field_id'" [items]="fields" [name]="'Category'"></app-custom-select-dropdown>
      </div>
      <div class="form-group">
        <label for="comment">Comment</label>
        <textarea formControlName="comment" id="comment" name="comment" placeholder="Enter comment"></textarea>
      </div>
    }
    <div class="form-group">
      <button [disabled]="form.invalid">Create</button>
    </div>
  </form>
</div>
