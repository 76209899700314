import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AuthService} from "./auth.service";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {ScheduledTransactions} from "../models/ScheduledTransactions";

@Injectable({
  providedIn: 'root'
})
export class ScheduleTransactionService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  create(scheduledTransactions: ScheduledTransactions): Observable<any> {
    return this.http.post<any>(environment.backendUrl + '/scheduledTransactions', scheduledTransactions, {withCredentials: true});
  }

  fetch(page: number): Observable<any> {
    return this.http.get<Observable<any>>(environment.backendUrl + '/scheduledTransactions?page=' + page, {withCredentials: true});
  }
}
