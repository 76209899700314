<div class="box create-new-item" routerLink="transaction">
  <div class="icon">
    <mat-icon class="material-icons-outlined">account_balance</mat-icon>
  </div>
  <div class="info">
    <div class="title">
      <h2>New transaction</h2>
    </div>
    <p>Create new transaction</p>
  </div>
</div>
<div class="box create-new-item" routerLink="note">
  <div class="icon">
    <mat-icon class="material-icons-outlined">text_snippet</mat-icon>
  </div>
  <div class="info">
    <div class="title">
      <h2>New Note</h2>
      <p>Create new note</p>
    </div>
  </div>
</div>
<div class="box create-new-item" routerLink="schedule-transaction">
  <div class="icon">
    <mat-icon class="material-icons-outlined">schedule</mat-icon>
  </div>
  <div class="info">
    <div class="title">
      <h2>Schedule transaction</h2>
      <p>If you want to add a transaction at exact time or repeat it blq blq lbq</p>
    </div>
  </div>
</div>
