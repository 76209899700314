import {Component} from '@angular/core';
import {CheckForUpdateService} from "../../services/check-for-update.service";
import {MatIcon} from "@angular/material/icon";
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-check-for-updates',
  standalone: true,
  imports: [
    MatIcon,
    NgClass
  ],
  templateUrl: './check-for-updates.component.html',
  styleUrl: './check-for-updates.component.css'
})
export class CheckForUpdatesComponent {
  constructor(public checkForUpdateService: CheckForUpdateService) {
  }

  applyUpdate(): void {
    this.checkForUpdateService.applyUpdate();
    this.checkForUpdateService.isNewVersionAvailable = false;
  }
}
