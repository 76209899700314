<div id="calendar" #calendarContainer>
  <div id="calendar_nav">
    <div id="calendar-back" class="btn" (click)="previousMonth()"><mat-icon class="material-icons-outlined">arrow_back_ios_new</mat-icon></div>
    <span>{{date | date: 'MMMM Y'}}</span>
    <div id="calendar-forward" class="btn" (click)="nextMonth()"><mat-icon class="material-icons-outlined">arrow_forward_ios_new</mat-icon></div>
    <div id="filters" class="btn" (click)="toggleFilters()" matTooltip="Toggle filters"><mat-icon class="material-icons-outlined">{{filtersText}}</mat-icon></div>
  </div>
  <div class="filters" [ngClass]="{'show': filtersActivated, 'hide': !filtersActivated}" #filters>
    <input type="text" placeholder="Filter by category or comment..." (input)="searchEvent($event)" value="{{filtersSearchText}}">
    <div id="labels" class="btn" matTooltip="Labels" (click)="openKeywordsCloud()" [hidden]="!keywords.length"><mat-icon class="material-icons-outlined">label</mat-icon></div>
    <div id="clear_filters" class="btn" (click)="clearFilters()" matTooltip="Clear"><mat-icon class="material-icons-outlined">clear</mat-icon></div>
  </div>
  @if (!loading) {
    <div id="calendar_body">
      @for (day of calendar; track $index) {
        <div class="day" [ngClass]="{'disabled': day === 0, 'today': day.active}">
          <div class="day_number">{{ day.namedDay }} {{day.day}}</div>
          @for (item of events[day.day]; track item.created_at) {
            @if (item.hasOwnProperty('id')) {
              @if (!item.hasOwnProperty('visible') || item.visible) {
                <div class="day_item" (click)="viewTransaction(item)" [ngClass]="{'has-comment': item.comment.toString().length > 0}">
                  <span class="item-time">{{item.created_at * 1000 | date: 'HH:mm'}}</span>
                  <span class="item-category" [matTooltip]="getName(item)">{{item.category_name}}</span>
                  <span class="item-amount {{item.type}}">{{item.amount | currency: user.currency : 'symbol-narrow' | fixCurrency}}</span>
                </div>
              }
            } @else {
              <div class="day_summary">
                <div class="content" [ngStyle]="{'visibility': (item.income || item.spent) ? 'visible' : 'hidden'}">
                  <span class="positive">{{item.income | currency: user.currency : 'symbol-narrow' | fixCurrency}}</span>
                  <span class="negative">{{item.spent | currency: user.currency : 'symbol-narrow' | fixCurrency}}</span>
                  <div class="percentage-line" style="background: linear-gradient(to right, #1bc47d 0%, #1bc47d {{item.income_percent}}%, #fa6464 0%, #fa6464 {{item.spent_percent}}%);"></div>
                </div>
              </div>
            }
          }
        </div>
      }
    </div>
  } @else {
    <div class="loader">
      <mat-progress-spinner strokeWidth="2" mode="indeterminate"></mat-progress-spinner>
    </div>
  }
</div>
