<div class="box">
  <div class="title">
    <h2>{{this.authService.user.display_name}}'s account</h2>
  </div>
  <form [formGroup]="form" (ngSubmit)="onSubmit(form)">
    <div class="form-group">
      <label for="email">Email</label>
      <input formControlName="email" id="email" name="email" readonly>
    </div>
    <div class="form-group">
      <label for="display_name">Display name</label>
      <input formControlName="display_name" id="display_name" name="display_name">
    </div>
    <div class="form-group">
      <app-select-dropdown [form]="form" [name]="'Currency'" [key]="'currency'" [items]="getCurrencies()" [selectedId]="this.authService.user.currency" [selectedName]="getCurrencyDetails(this.authService.user.currency)"></app-select-dropdown>
    </div>
    <div class="form-group">
      <app-select-dropdown [form]="form" [name]="'Timezone'" [key]="'timezone'" [items]="getTimezones()" [selectedId]="this.authService.user.timezone" [selectedName]="this.authService.user.timezone"></app-select-dropdown>
    </div>
    <div class="form-group">
      <button [disabled]="form.invalid">UPDATE</button>
    </div>
  </form>
</div>
