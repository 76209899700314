import {Component, EventEmitter, OnInit} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {CustomSelectDropdownComponent} from "../custom-select-dropdown/custom-select-dropdown.component";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {SelectDropdownComponent} from "../select-dropdown/select-dropdown.component";
import {FieldsService} from "../../services/fields.service";

@Component({
  selector: 'app-edit-budget',
  standalone: true,
  imports: [
    MatIcon,
    CustomSelectDropdownComponent,
    FormsModule,
    ReactiveFormsModule,
    SelectDropdownComponent
  ],
  templateUrl: './edit-budget.component.html',
  styleUrl: './edit-budget.component.css'
})
export class EditBudgetComponent implements OnInit {
  form!: FormGroup;
  modalRef!: any;
  field!: any;
  month!: any;
  year!: number;
  categoryName!: string | null;
  originalValue!: null;

  constructor(
    private fieldsService: FieldsService,
    private formBuilder: FormBuilder
  ) {

  }

  ngOnInit() {
    let budget = this.field.budget_history[this.month + '-' + this.year]?.value;
    this.originalValue = budget;

    this.form = this.formBuilder.group({
      budget: new FormControl(budget, [Validators.pattern("^[0-9]+(\.[0-9]{1,2})?$")]),
      reason: new FormControl(null)
    });
  }

  closeModal(): void {
    this.modalRef.close();
  }

  onSubmit(form: FormGroup): void {
    if (this.form.valid) {
      if (!this.field.budget_history.hasOwnProperty(this.month + '-' + this.year)) {
        this.field.budget_history[this.month + '-' + this.year] = {
          value: null,
          updated_at: 0
        };
      }

      let newBudget = form.value.budget !== "" ? parseFloat(form.value.budget) : null;
      this.field.budget_history[this.month + '-' + this.year].value = newBudget;

      this.fieldsService.updateBudget(this.field, this.year, parseInt(this.month), newBudget, form.value.reason).subscribe((r:any) => {
        if (!r.error) {
          this.field.budget_history[this.month + '-' + this.year].updated_at = r.data.updated_at;
        } else {
          this.field.budget_history[this.month + '-' + this.year].value = this.originalValue;
        }

        this.modalRef.close();
      });
    }
  }
}
