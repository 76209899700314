import { HttpInterceptorFn } from '@angular/common/http';
import {LoadingService} from "../shared/loading.service";
import {inject} from "@angular/core";
import {finalize} from "rxjs";

export const loadingInterceptor: HttpInterceptorFn = (req, next) => {
  const loadingService: LoadingService = inject(LoadingService);

  if (!req.url.includes('/utils/keepAlive')) {
    loadingService.setLoading(true);

    return next(req).pipe(
      finalize(() => {
        loadingService.setLoading(false);
      })
    );
  }

  return next(req);
};
