import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  constructor(private http: HttpClient) { }

  add(name: string, parentId: string | null, type: string): Observable<any> {
    return this.http.post<Observable<any>>(environment.backendUrl + '/categories', {
      name: name,
      parent_category_id: parentId,
      type: type
    }, {withCredentials: true});
  }

  fetchAll(parentId: number = 0, search: any = '', type: string = 'income'): Observable<any> {
    return this.http.get<Observable<any>>(environment.backendUrl + '/categories/?parent_category_id=' + parentId + '&search=' + search + '&type=' + type, {withCredentials: true});
  }

  grouped(type: string = 'income'): Observable<any> {
    return this.http.get<Observable<any>>(environment.backendUrl + '/categories/grouped/' + type, {withCredentials: true});
  }
}
