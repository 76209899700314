<div class="box">
  <div class="title">
    <h2>New note</h2>
  </div>
  <p>Add new notes when you don't have enough time to create a record.</p>
  <form [formGroup]="form" (ngSubmit)="onSubmit(form)">
    <div class="form-group">
<!--      <label for="note">Note</label>-->
      <textarea formControlName="note" id="note" name="note" placeholder="Enter note"></textarea>
    </div>
    <div class="form-group">
      <button [disabled]="form.invalid">Create</button>
    </div>
  </form>
</div>
