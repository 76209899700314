import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {AuthService} from "../../services/auth.service";
import {Router, RouterLink} from "@angular/router";
import {CommonModule} from "@angular/common";
import {Observable} from "rxjs";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-sign-in',
  standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterLink,
        MatIcon
    ],
  templateUrl: './sign-in.component.html',
  styleUrl: './sign-in.component.css'
})
export class SignInComponent implements OnInit {
  form!: FormGroup;
  authService: AuthService = inject(AuthService);
  router: Router = inject(Router);
  submitted: boolean = false;
  errorMessage: string = '';

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required)
    });
  }

  get email() {
    return this.form.controls['email'];
  }

  get password() {
    return this.form.controls['password'];
  }

  onSubmit(form: FormGroup) : void {
    this.submitted = true;

    if (this.form.valid) {
      this.authService.signIn(form.value).subscribe(
        (item) => {
          if (!item.error) {
            this.authService.isAuthenticated = true;
            this.authService.user = item.data.user;
            this.router.navigate(['']);
          } else {
            this.errorMessage = item.error_details.error_message;
          }
        }
      );
    }
  }
}
