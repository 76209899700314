@if (!type) {
  <div class="box field-types income" [routerLink]="['/fields-overview', 'income']">
    <div class="icon">
      <mat-icon class="material-icons-outlined">date_range</mat-icon>
    </div>
    <div class="info">
      <div class="title">
        <h2>Income expectation overview</h2>
        <p>Preview and/or change your expectation in compact view</p>
      </div>
    </div>
  </div>
  <div class="box field-types spent" [routerLink]="['/fields-overview', 'spent']">
    <div class="icon">
      <mat-icon class="material-icons-outlined">date_range</mat-icon>
    </div>
    <div class="info">
      <div class="title">
        <h2>Spent budget overview</h2>
        <p>Preview and/or change your budget in compact view</p>
      </div>
    </div>
  </div>
} @else {
  <div class="box">
    <div class="title">
      <h2>{{type | titlecase }} fields overview</h2>
      @if (fields.length) {
        <div class="options">
          <div matTooltip="Collapse/Expand all" (click)="toggleCollapseExpandAll($event)">
            <mat-icon class="material-icons-outlined">height</mat-icon>
          </div>
        </div>
      }
    </div>
    @if (fields.length) {
      <div class="scroll-pagination">
        <div (click)="previousStatsPage()" class="btn"><mat-icon class="material-icons-outlined">arrow_back_ios_new</mat-icon></div>
        <div class="pages">
          @for (page of carousel.getPages(); track $index) {
            <span (click)="carouselPage(page + 1)" [ngClass]="{'current': carousel.getCurrentPage() === (page + 1)}"></span>
          }
        </div>
        <div (click)="nextStatsPage()" class="btn"><mat-icon class="material-icons-outlined">arrow_forward_ios_new</mat-icon></div>
      </div>
      <div class="table-groups {{type}}_fields" #holdingContainer>
      <div class="table-groups">
        <div class="table-group-row group-header">
          <div class="table-group-cell">Year</div>
          <div class="table-group-cell-scrollable">
            @for (month of months; track $index) {
              <div class="table-group-cell">{{ month }}</div>
            }
          </div>
        </div>
        <div class="table-group-row sum">
          <div class="table-group-cell">{{year}}<span class="total">Total</span></div>
          <div class="table-group-cell-scrollable">
            @for (month of months; track $index) {
              <div class="table-group-cell">
                {{ getTotal(($index + 1)) | currency: user.currency : 'symbol-narrow' | fixCurrency }}
              </div>
            }
          </div>
        </div>
      </div>
      @for(item of fields; track item.id) {
        <div class="table-groups">
          <div class="table-group-row group-header category-name cursor-pointer" id="field_{{item.id}}">
            <div class="table-group-cell" (click)="toggleCollapseExpand($event)" matTooltip="Toggle">{{item.name}}</div>
            <div class="table-group-cell-scrollable">
              @for (month of months; track $index) {
                <div class="table-group-cell" (click)="toggleCollapseExpand($event)" matTooltip="Toggle">{{ month }}</div>
              }
            </div>
          </div>
          @for(field of item.fields; track field.id) {
            <div class="table-group-row" id="field_{{field.id}}">
              <div class="table-group-cell name" [routerLink]="['/fields', type]" [fragment]="'field_' + field.id">{{field.name}}</div>
              <div class="table-group-cell-scrollable">
                @for (month of months; track $index) {
                  <div class="table-group-cell {{compareAmountToBudget(field, ($index + 1))}}"
                       [ngClass]="{'editable': (canEdit(($index  + 1)) && isEnabled(field, ($index + 1)))}"
                  >
                    @if (isEnabled(field, ($index + 1))) {
                      <span [ngClass]="{'hasTransactions': hasAnyTransactions(field, ($index + 1))}" (click)="showTransactions(field, $index)" [matTooltip]="getTooltipStats(field, ($index + 1))">
                        {{ getBudget(field, ($index + 1)) | currency: user.currency : 'symbol-narrow' | fixCurrency }}
                      </span>
                      <mat-icon class="material-icons-outlined" (click)="openForEdit($event, field, ($index + 1), item.name)" matTooltip="Edit">edit</mat-icon>
                    } @else {
                      <span class="negative" matTooltip="Disabled">x</span>
                    }
                  </div>
                }
              </div>
            </div>
          }
          <div class="table-group-row sum">
            <div class="table-group-cell"><span class="total">Total</span></div>
            <div class="table-group-cell-scrollable">
              @for (month of months; track $index) {
                <div class="table-group-cell {{compareAmountToBudget(item, ($index + 1))}}" [ngClass]="{'hasTransactions': hasAnyTransactions(item, ($index + 1))}" (click)="showTransactions(item, $index)" [matTooltip]="getTooltipStats(item, ($index + 1))">
                    {{ getGroupTotal(item, ($index + 1)) | currency: user.currency : 'symbol-narrow' | fixCurrency }}
                </div>
              }
            </div>
          </div>
        </div>
      }
    </div>
    } @else {
      @if (loading) {
        <div class="loader">
          <mat-progress-spinner strokeWidth="2" mode="indeterminate"></mat-progress-spinner>
        </div>
      } @else {
        Please configure your fields first.
      }
    }
  </div>

}
