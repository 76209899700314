import {Component, DestroyRef, EventEmitter, inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {NotesService} from "../../services/notes.service";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {Notes} from "../../models/Notes";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-notes-edit',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatIcon
  ],
  templateUrl: './notes-edit.component.html',
  styleUrl: './notes-edit.component.css'
})
export class NotesEditComponent {
  note!: Notes;
  form!: FormGroup;
  router: Router = inject(Router);
  flashMessage: EventEmitter<{message:string, type:string}> = new EventEmitter<{message: string; type: string}>();
  loading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private notesService: NotesService,
    private destroyRef: DestroyRef,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(params => {
      this.notesService.get(params['id']).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((r: any) => {
        if (!r.error) {
          this.note = r.data;

          this.form = this.formBuilder.group({
            text: new FormControl(this.note.text, Validators.required),
          });

          this.loading = true;
        }
      });
    });
  }

  get text() {
    return this.form.controls['text'];
  }

  onSubmit(form: FormGroup) : void {
    if (this.form.valid) {
      this.note.text = form.value.text;
      this.notesService.edit(this.note).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((r:any) => {
        if (!r.error) {
          this.router.navigate(['notes']);
          this.flashMessage.emit({message: 'Note was edited successful.', type: 'done'});
        } else {
          this.flashMessage.emit({message: 'There was an error while trying to edit this note.', type: 'error'});
        }
      });
    }
  }

  delete(): void {
    if (confirm('Are you sure?')) {
      this.notesService.delete(this.note).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((r:any) => {
        if (!r.error) {
          this.router.navigate(['/notes']);
          this.flashMessage.emit({message: 'Note was remove successful.', type: 'done'});
        } else {
          this.flashMessage.emit({message: 'There was an error while trying to delete this note.', type: 'error'});
        }
      });
    }
  }
}
