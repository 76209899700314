@if (name) {
  <label>{{name}}</label>
}
<div class="dropdown" (click)="toggleDropdown($event);" [ngClass]="{'dropdown-active': toggleDropdownStatus}">
  <div class="dropdown-selected-value">{{selectedName}}</div>
  <div class="dropdown-list">
    @for (item of items; track item.id) {
      <div class="dropdown-item" [ngClass]="{'selected': item.id === selectedId}" (click)="changeValue(item)">{{item.name}}</div>
    }
  </div>
</div>
