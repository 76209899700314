import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isLoading$: Observable<any> = this.isLoading.asObservable();

  setLoading(isLoading: boolean): void {
    this.isLoading.next(isLoading);
  }
}
