import {CanActivateFn, Router} from '@angular/router';
import {AuthService} from "../services/auth.service";
import {inject} from "@angular/core";
import {map} from "rxjs";
import {User} from "../models/User";

export const notAuthGuard: CanActivateFn = (route, state) => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);

  if (authService.isAuthenticated) {
    router.navigate(['']);
  }

  return true;
  // const authService: AuthService = inject(AuthService);
  // const router: Router = inject(Router);
  //
  // return authService.verifyCookie().pipe(map(r => {
  //   let result = false;
  //
  //   if (!r.error) {
  //     authService.isAuthenticated = false;
  //     authService.user = {} as User;
  //     router.navigate(['sign-in']);
  //     result = true;
  //   } else {
  //     router.navigate(['']);
  //   }
  //
  //   return result;
  // }));
};
