import {Component, DestroyRef, OnInit} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CreateNoteComponent} from "../../components/create-note/create-note.component";
import {ListNotesComponent} from "../../components/list-notes/list-notes.component";
import {Notes} from "../../models/Notes";
import {NotesService} from "../../services/notes.service";
import {ContainerScrollService} from "../../shared/container-scroll.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-notes',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CreateNoteComponent,
    ListNotesComponent
  ],
  templateUrl: './notes.component.html',
  styleUrl: './notes.component.css'
})
export class NotesComponent implements OnInit {
  title: string = 'Notes';
  notes: Notes[] = [];
  page: number = 1;
  limit: number = 50;
  loading: boolean = true;
  hasMorePages: boolean = true;

  constructor(
    private notesService: NotesService,
    private containerScrollService: ContainerScrollService,
    private destroyRef: DestroyRef) {
  }

  ngOnInit() {
    this.getData();

    this.containerScrollService.scroll$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(event => {
      if (event !== null) {
        if ((event.target.scrollTop + event.target.offsetHeight) >= (event.target.scrollHeight - 300)) {
          if (!this.loading && this.hasMorePages) {
            ++this.page;
            this.getData();
          }
        }
      }
    });
  }

  private getData(): void {
    this.loading = true;
    this.notesService.fetch(this.page).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((r: any) => {
      if (!r.error) {
        r.data.notes.forEach((item: any) => this.notes.push(item));
        this.hasMorePages = r.data.notes.length === this.limit;
        this.loading = false;
      }
    });
  }
}
