<div class="box">
  <div class="title">
    <h2>Labels</h2>
    <div class="options">
      <div (click)="closeModal()" matTooltip="Close"><mat-icon class="material-icons-outlined">close</mat-icon></div>
    </div>
  </div>
  <div id="keywords-container">
    @for (keyword of list; track $index) {
      <span (click)="selectKeyword(keyword[0])">{{keyword[0]}} ({{keyword[1]}})</span>
    }
  </div>
</div>
