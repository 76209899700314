import {Component, EventEmitter} from '@angular/core';
import {TransactionsService} from "../../services/transactions.service";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {CustomSelectDropdownComponent} from "../../components/custom-select-dropdown/custom-select-dropdown.component";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {SelectDropdownComponent} from "../../components/select-dropdown/select-dropdown.component";
import {Transactions} from "../../models/Transactions";
import {TitleCasePipe} from "@angular/common";
import {FieldsService} from "../../services/fields.service";
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'app-transactions-edit',
  standalone: true,
  imports: [
    CustomSelectDropdownComponent,
    FormsModule,
    ReactiveFormsModule,
    SelectDropdownComponent,
    TitleCasePipe,
    MatIcon,
    MatTooltip,
    RouterLink
  ],
  templateUrl: './transactions-edit.component.html',
  styleUrl: './transactions-edit.component.css'
})
export class TransactionsEditComponent {
  form!: FormGroup;
  transaction!: Transactions;
  fields: any;
  loadedFieldsCategories: any = {};
  loading: boolean = true;
  transactionFieldName: string = '';
  flashMessage: EventEmitter<{message:string, type:string}> = new EventEmitter<{message: string; type: string}>();

  constructor(
    private route: ActivatedRoute,
    private transactionsService: TransactionsService,
    private fieldsService: FieldsService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.route.params.subscribe(params => {
      this.transactionsService.get(params['id']).subscribe((result: any) => {
        this.transaction = result.data;

        this.fieldsService.getGroupedByCategories(0, this.transaction.type).subscribe(r => {
          this.fields = r.data.fields;
          this.loadedFieldsCategories[this.transaction.type] = r.data.fields;

          let date = new Date(this.transaction.created_at * 1000);
          let dateFormat = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0') + 'T' + String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0');

          this.form = this.formBuilder.group({
            created_at: new FormControl(dateFormat, [Validators.required]),
            type: new FormControl(this.transaction.type, [Validators.required]),
            amount: new FormControl(this.transaction.amount, [Validators.required, Validators.pattern("^[0-9]+(\.[0-9]{1,2})?$")]),
            field_id: new FormControl(this.transaction.field_id, [Validators.required]),
            comment: new FormControl(this.transaction.comment)
          });

          this.form.valueChanges.subscribe(data => {
            if (['income', 'spent'].includes(data.type) && !this.loadedFieldsCategories.hasOwnProperty(data.type)) {
              this.fieldsService.getGroupedByCategories(0, data.type).subscribe(r => {
                this.fields = r.data.fields;
                this.loadedFieldsCategories[data.type] = r.data.fields;
              });
            } else {
              this.fields = this.loadedFieldsCategories[data.type];
            }
          });

          this.fields.filter((x:any) => {
            if (x.fields.length) {
              x.fields.filter((r:any) => {
                if (r.id === this.transaction.field_id) {
                  this.transactionFieldName = r.name;
                }
              });
            }
          });

          this.loading = false;
        });
      });
    });
  }

  toggleDatePicker(event: any) {
    event.target.showPicker();
  }

  get amount() {
    return this.form.controls['amount'];
  }

  get field() {
    return this.form.controls['field_id'];
  }

  get comment() {
    return this.form.controls['comment'];
  }

  get type() {
    return this.form.controls['type'];
  }

  delete(): void {
    if (confirm('Are you sure?')) {
      this.transactionsService.delete(this.transaction).subscribe((r:any) => {
        if (!r.error) {
          this.router.navigate(['transactions']);
          this.flashMessage.emit({message: 'Transaction was remove successful.', type: 'done'});
        } else {
          this.flashMessage.emit({message: 'There was an error while trying to delete this transaction.', type: 'error'});
        }
      });
    }
  }

  onSubmit(form: FormGroup): void {
    if (form.valid) {
      let updatedData = {...form.value};
      updatedData.created_at = Math.ceil(new Date(updatedData.created_at.replace(/-/g, '/').replace('T', ' ')).getTime() / 1000);

      console.log(this.transaction);
      console.log({...this.transaction,...updatedData});

      this.transactionsService.update({...this.transaction,...updatedData}).subscribe((r:any) => {
        if (!r.error) {
          Object.assign(this.transaction, r.data);

          this.flashMessage.emit({message: 'The transaction was updated', type: 'done'});
        } else {
          this.flashMessage.emit({message: 'The transaction was not updated', type: 'error'});
        }
      });
    }
  }
}
