import { ApplicationConfig, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideServiceWorker } from '@angular/service-worker';
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import {provideAnimations, provideNoopAnimations} from "@angular/platform-browser/animations";
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from "@angular/material/tooltip";
import {loadingInterceptor} from "./interceptors/loading.interceptor";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideServiceWorker('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000'
    }),
    provideHttpClient(withInterceptors([loadingInterceptor])),
    provideNoopAnimations(),
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        // showDelay: 200,
        // hideDelay: 200
        disableTooltipInteractivity: true
      }
    },
    provideAnimations()
  ]
};
