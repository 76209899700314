<div class="box">
  <div class="title">
    <h2>Change budget</h2>
    <div class="options">
      <div (click)="closeModal()"><mat-icon class="material-icons-outlined">close</mat-icon></div>
    </div>
  </div>
  <form [formGroup]="form" (ngSubmit)="onSubmit(form)">
    <div class="form-group">
      <label for="budget">Budget</label>
      <input id="budget" type="text" name="budget" formControlName="budget">
    </div>
    <div class="form-group">
      <label for="reason">Reason</label>
      <input id="reason" type="text" name="reason" formControlName="reason">
    </div>
    <div class="form-group">
      <label for="month">Month</label>
      <input id="month" type="text" name="month" value="{{month}}/{{year}}" readonly>
    </div>
    <div class="form-group">
      <label for="field">Field</label>
      <input id="field" type="text" name="field" value="{{categoryName}} - {{field.name}}" readonly>
    </div>
    <div class="form-group">
      <button [disabled]="form.invalid">Update</button>
    </div>
  </form>
</div>
