import {Component, EventEmitter, OnInit} from '@angular/core';
import {CreateNoteComponent} from "../../components/create-note/create-note.component";
import {ListNotesComponent} from "../../components/list-notes/list-notes.component";
import {FieldsService} from "../../services/fields.service";
import {CategoriesService} from "../../services/categories.service";
import {Categories} from "../../models/Categories";
import {FieldsByCategories} from "../../models/FieldsByCategories";
import {MatIcon} from "@angular/material/icon";
import {CurrencyPipe, DecimalPipe, NgClass, PercentPipe, TitleCasePipe} from "@angular/common";
import {ActivatedRoute, Router, RouterLink, RouterLinkActive} from "@angular/router";
import {SelectDropdownComponent} from "../../components/select-dropdown/select-dropdown.component";
import {Fields} from "../../models/Fields";
import {CustomAutocompleteComponent} from "../../components/custom-autocomplete/custom-autocomplete.component";
import {AuthService} from "../../services/auth.service";
import {NgApexchartsModule} from "ng-apexcharts";
import {MatTooltip} from "@angular/material/tooltip";
import {FixCurrencyPipe} from "../../pipes/fix-currency.pipe";
import {EditBudgetComponent} from "../../components/edit-budget/edit-budget.component";
import {Dialog, DialogModule} from "@angular/cdk/dialog";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
  selector: 'app-fields',
  standalone: true,
  imports: [
    CreateNoteComponent,
    ListNotesComponent,
    MatIcon,
    NgClass,
    RouterLink,
    RouterLinkActive,
    TitleCasePipe,
    DecimalPipe,
    SelectDropdownComponent,
    CustomAutocompleteComponent,
    CurrencyPipe,
    NgApexchartsModule,
    PercentPipe,
    MatTooltip,
    FixCurrencyPipe,
    DialogModule,
    MatProgressSpinner
  ],
  templateUrl: './fields.component.html',
  styleUrl: './fields.component.css'
})
export class FieldsComponent implements OnInit {
  title: string = 'Fields';
  categories: Categories[] = [];
  fields: FieldsByCategories[] = [];
  type: string = '';
  activePeriods: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  user: any;
  flashMessage: EventEmitter<{message:string, type:string}> = new EventEmitter<{message: string; type: string}>();
  loading: boolean = true;

  constructor(
    private fieldsService : FieldsService,
    private categoriesService : CategoriesService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private dialog: Dialog
  ) {
    this.user = this.authService.user;
  }

  ngOnInit() {
    this.route.url.subscribe(params => {
        if (params.length) {
          if (!['income', 'spent'].includes(params[0].path)) {
            this.router.navigate(['/fields']);
          } else {
            this.type = params[0].path;

            this.categoriesService.grouped(this.type).subscribe(r => {
              this.categories = r.data.categories;
            });

            this.fieldsService.getGroupedByCategories(1, this.type).subscribe(r => {
              this.fields = r.data.fields;
              this.loading = false;
            });
          }
        } else {
          this.router.navigate(['/fields']);
        }
    });

    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        let container = document.getElementById('layout-container') as HTMLElement;

        if (container) {

          let interval = setInterval(() => {
            let el = container.querySelector('#' + fragment);
            if (el !== null) {
              container.scrollTo({
                top: (el.getBoundingClientRect().y - (window.innerHeight / 2)),
                behavior: 'smooth'
              });

              el.classList.add('highlighted');

              clearInterval(interval);
            }
          }, 100);
        }
      }
    });
  }

  add(): void {
    let field: FieldsByCategories = {} as FieldsByCategories;
    field.id = null;
    field.name = null;
    field.fields = [];

    this.fields.push(field);
  }

  handleCategoryChange(event: any, parentCategory?: any, fieldId?: any): void {
    if (event.id !== null && !fieldId) { // existing category
      if (typeof event.fields !== 'undefined' && !event.fields.length) { // add field to selected category
        this.fields.filter(x => {
          if(x.id === event.id) {
            let initField: Fields = this.defaultFieldStructure();
            x.fields.push(initField);
          }
        });
      } else { // create new field from existing main and sub category
        parentCategory.fields.filter((field: Fields) => {
          if (field.id === null) {
            field.category_id = event.id;
            field.category_name = event.name;

            this.fieldsService.add(field).subscribe(r => {
              if (!r.error) {
                Object.assign(field, r.data);
                field.name = event.name;
                this.flashMessage.emit({message: 'New field was created.', type: 'done'});
              } else {
                this.flashMessage.emit({message: 'There were a problem with creation of this field.', type: 'error'});
              }
            });
          }
        });
      }
    } else { // new category
      if (event.id === null && typeof parentCategory === 'undefined' && !fieldId) { // new parent category
        this.categoriesService.add(event.name, null, this.type).subscribe(r => {
          if (!r.error) {
            Object.assign(event, r.data);
            this.categories.push(r.data);

            let initField = this.defaultFieldStructure();
            event.fields.push(initField);

            this.flashMessage.emit({message: 'New main category was created.', type: 'done'});
          } else {
            this.flashMessage.emit({message: 'There were a problem with creation of a new main category.', type: 'error'});
          }
        });
      } else if (event.id === null && typeof parentCategory !== 'undefined' && !fieldId) { // new child category
        this.categoriesService.add(event.name, parentCategory.id, this.type).subscribe(r => {
          if (!r.error) {
            this.categories.push(r.data);

            parentCategory.fields.filter((field: Fields) => {
              if (field.id === null) {
                field.category_id = r.data.id;
                field.category_name = event.name;

                this.fieldsService.add(field).subscribe(r => {
                  if (!r.error) {
                    Object.assign(field, r.data);
                    field.name = event.name;
                    this.flashMessage.emit({message: 'New field was created.', type: 'done'});
                  } else {
                    this.flashMessage.emit({message: 'There were a problem with creation of this field.', type: 'error'});
                  }
                });
              }
            });
          } else {
            this.flashMessage.emit({message: 'There were a problem with creation of a new category.', type: 'error'});
          }
        });
      } else { // update field to new selected category
        if (event.id === null) { // create new
          this.categoriesService.add(event.name, parentCategory.id, this.type).subscribe(r => {
            if (!r.error) {
              this.categories.push(r.data);

              parentCategory.fields.filter((field: Fields) => {
                if (field.id === fieldId) {
                  let originalCategoryId = field.category_id;
                  let originalCategoryName = field.name || field.category_name;
                  field.category_id = r.data.id;
                  field.category_name = r.data.name;
                  field.name = r.data.name;

                  this.fieldsService.changeCategory(field).subscribe(r => {
                    if (!r.error) {
                      this.flashMessage.emit({message: 'Field category was changed successful.', type: 'done'});
                    } else {
                      field.category_id = originalCategoryId;
                      field.category_name = originalCategoryName;
                      field.name = originalCategoryName;
                      this.flashMessage.emit({message: 'Field category cannot be change right now.', type: 'error'});
                    }
                  });
                }
              });
            } else {
              this.flashMessage.emit({message: 'There were a problem with creation of a new category.', type: 'error'});
            }
          });
        } else { // overwrite with existing one
          parentCategory.fields.filter((field: Fields) => {
            if (field.id === fieldId && event.id !== field.category_id) {
              let originalCategoryId = field.category_id;
              let originalCategoryName = field.name || field.category_name;
              field.category_id = event.id;
              field.category_name = event.name;
              field.name = event.name;

              this.fieldsService.changeCategory(field).subscribe(r => {
                if (!r.error) {
                  this.flashMessage.emit({message: 'Field category was changed successful.', type: 'done'});
                } else {
                  field.category_id = originalCategoryId;
                  field.category_name = originalCategoryName;
                  field.name = originalCategoryName;
                  this.flashMessage.emit({message: 'Field category cannot be change right now.', type: 'error'});
                }
              });
            }
          });
        }
      }
    }
  }

  toggleActivePeriod(event: any, field: Fields): void {
    if (field.id === null) {
      return;
    }

    event.target.classList.toggle('selected');
    const oldPeriod = field.active_period;
    const periodList = [...event.target.closest('.active_period').querySelectorAll('.selected')].map((item: any) => {
      return item.getAttribute('id').replace('key_', '');
    });

    field.active_period = (periodList.length ? periodList.join(',') : null);

    this.fieldsService.changeActivePeriod(field).subscribe(r => {
      if (r.error) {
        field.active_period = oldPeriod;
        event.target.classList.toggle('selected');
        this.flashMessage.emit({message: 'Something went wrong.', type: 'error'});
      } else {
        this.flashMessage.emit({message: 'Changes are saved!', type: 'done'});
      }
    });
  }

  updateBudgetInterval: any;

  updateBudget(event: any, field: Fields): void {
    let currentValue = (event.target.value.trim() !== '' ? event.target.value.trim() : null);

    if (currentValue === null || (event.target.value.trim() !== '' ? event.target.value.trim() : null).match(/^[0-9\.]+$/)) {
      clearInterval(this.updateBudgetInterval); // reset the previous one

      this.updateBudgetInterval = setInterval(() => {
        let currentValue = (event.target.value.trim() !== '' ? event.target.value.trim() : null);
        let oldValue = field.budget;

        field.budget = currentValue;

        if (currentValue === null || /^\d+(\.\d+)?$/.test(currentValue)) {
          this.fieldsService.changeBudget(field).subscribe(r => {
            if (r.error) {
              event.target.value = oldValue;
              field.budget = oldValue;

              this.flashMessage.emit({message: 'Cannot update ' + (this.type === 'spent' ? 'budget' : 'expected') + ' right now.', type: 'error'});
            } else {
              this.flashMessage.emit({message: (this.type === 'spent' ? 'Budget' : 'Expected') + ' was changed successful.', type: 'done'});
            }
          });
        } else {
          event.target.value = oldValue;
          field.budget = oldValue;
          this.flashMessage.emit({message: 'Value must be a valid number!', type: 'error'});
        }

        clearInterval(this.updateBudgetInterval);
      }, 2000);
    } else {
      event.target.value = field.budget;
    }
  }

  openForEdit(event: any, field: any, category: string | null): void {
    if (event.target.closest('.table-group-cell').classList.contains('editable')) {
      const dialogRef = this.dialog.open(EditBudgetComponent, {
        panelClass: 'bsense-dialog',
      });

      dialogRef.componentInstance!.modalRef = dialogRef;
      dialogRef.componentInstance!.field = field;
      dialogRef.componentInstance!.month = (new Date().getMonth() + 1).toString().padStart(2, '0');
      dialogRef.componentInstance!.year = (new Date().getFullYear());
      dialogRef.componentInstance!.categoryName = category;
    }
  }

  currentBudget(field: any): number | null {
    let currentBudget = null;
    let date = new Date();
    let period = (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getFullYear();

    if (period in field.budget_history) {
      currentBudget = field.budget_history[period].value;
    }

    return currentBudget
  }

  getCategories(parentCategoryId: any | null): any {
    return this.categories.filter((r: any) => r.parent_category_id === parentCategoryId).sort((a: Categories,b: Categories) => a.name.toString().localeCompare(b.name));
  }

  copy(field: Fields, group: any): void {
    let copyField = {...field};
    copyField.id = null;
    copyField.category_id = null;
    copyField.category_name = null;
    copyField.name = null;
    copyField.updated_at = 0;
    copyField.created_at = 0;

    group.fields.push(copyField);
  }

  delete(field: Fields): void {
    if (field.id === null) {
      return;
    }

    if (confirm('Are you sure?')) {
      this.fieldsService.delete(field).subscribe(r => {
        if (!r.error) {
          this.fields.forEach(group => {
            group.fields = group.fields.filter(item => item.id !== field.id);
          });

          this.flashMessage.emit({message: 'Field was removed successful.', type: 'done'});
        } else {
          this.flashMessage.emit({message: 'Cannot remove this field right now', type: 'error'});
        }
      });
    }
  }

  private defaultFieldStructure(): Fields
  {
    const field: Fields = {
      category_id: null,
      category_name: null,
      id: null,
      type: this.type,
      updated_at: 0,
      created_at: 0,
      budget: null,
      budget_history: [],
      position: 0,
      user_id: this.authService.user.id,
      name: null,
      one_off: 0,
      is_active: 1,
      active_period: '1,2,3,4,5,6,7,8,9,10,11,12',
      active_period_history: [],
      due_day: null,
      due_month: null
    };

    return field;
  }

  // addNewInnerField(event: Event, index: number): void {
  //   const field = this.defaultFieldStructure();
  //   ++field.position;
  //
  //   this.fields[index].fields.push(field);
  // }
}
