import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Fields} from "../models/Fields";
import {AuthService} from "./auth.service";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FieldsService {
  constructor(private http: HttpClient, private auth: AuthService) {}

  getGroupedByCategories(includeFields: number = 1, type: string) : Observable<any> {
    return this.http.get<Observable<any>>(environment.backendUrl + '/fields?include_fields=' + includeFields + '&type=' + type, {withCredentials: true});
  }

  getGroupedByCategoriesForOverview(type: string) : Observable<any> {
    return this.http.get<Observable<any>>(environment.backendUrl + '/fields/forOverview?type=' + type, {withCredentials: true});
  }

  add(fields: Fields): Observable<any> {
    return this.http.post<Observable<any>>(environment.backendUrl + '/fields/', fields, {withCredentials: true});
  }

  get(id: string): Observable<any> {
    return this.http.get<Observable<any>>(environment.backendUrl + '/fields/' + id, {withCredentials: true});
  }

  delete(field: Fields): Observable<any> {
    return this.http.delete<Observable<any>>(environment.backendUrl + '/fields/' + field.id, {withCredentials: true});
  }

  toggleActive(field: Fields): Observable<any> {
    return this.http.post<Observable<any>>(environment.backendUrl + '/fields/' + field.id + '/toggleActive', {}, {withCredentials: true});
  }

  toggleOneOff(field: Fields): Observable<any> {
    return this.http.post<Observable<any>>(environment.backendUrl + '/fields/' + field.id + '/toggleOneOff', {}, {withCredentials: true});
  }

  changeActivePeriod(field: Fields): Observable<any> {
    return this.http.post<Observable<any>>(environment.backendUrl + '/fields/' + field.id + '/changeActivePeriod', {period: field.active_period}, {withCredentials: true});
  }

  changeBudget(field: Fields): Observable<any> {
    return this.http.post<Observable<any>>(environment.backendUrl + '/fields/' + field.id + '/changeBudget', {budget: field.budget}, {withCredentials: true});
  }

  updateBudget(field: Fields, year: number, month: number, budget: number | null, reason: string | null): Observable<any> {
    return this.http.post<Observable<any>>(environment.backendUrl + '/fields/' + field.id + '/updateBudget/' + year + '/' + month, {budget: budget, reason: reason}, {withCredentials: true});
  }

  changeCategory(field: Fields): Observable<any> {
    return this.http.post<Observable<any>>(environment.backendUrl + '/fields/' + field.id + '/changeCategory', {category_id: field.category_id}, {withCredentials: true});
  }
}
