import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {NotesService} from "../../services/notes.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-create-note',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './create-note.component.html',
  styleUrl: './create-note.component.css'
})
export class CreateNoteComponent implements OnInit {
  title: string = 'Create new note';
  form!: FormGroup;
  router: Router = inject(Router);

  constructor(private formBuilder: FormBuilder, private notesService: NotesService) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      note: new FormControl('', Validators.required)
    });
  }

  get note() {
    return this.form.controls['note'];
  }

  onSubmit(form: FormGroup) : void {
    if (this.form.valid) {
      this.notesService.add(form.value.note).subscribe(() => this.router.navigate(['notes']));
    }
  }
}
