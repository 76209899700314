import {CanActivateFn, Router} from '@angular/router';
import { inject } from "@angular/core";
import {AuthService} from "../services/auth.service";
import {map} from "rxjs";

export const authGuard: CanActivateFn = (route, state) => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);

  return authService.verifyCookie().pipe(map(r => {
    let result = false;

    if (!r.error && r.data.hasOwnProperty('user')) {
      authService.isAuthenticated = true;
      authService.user = r.data.user;
      result = true;
    } else {
      router.navigate(['sign-in']);
    }

    return result;
  }));
};
