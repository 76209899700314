<div class="box">
  <div class="title">
    <h2>Edit field</h2>
  </div>
  <p></p>
  @if (!loading) {
    <form [formGroup]="form" (ngSubmit)="onSubmit(form)">
      <div class="form-group">
        <label for="type">Type</label>
        <input type="text" formControlName="type" id="type" name="type">
      </div>
      <div class="form-group">
        <label for="updated_at">Last updated at</label>
        <input type="text" id="updated_at" name="updated_at"  value="{{field.updated_at * 1000 | date: 'd MMMM, y HH:mm'}}" readonly>
      </div>
      <div class="form-group">
        <label for="created_at">Created at</label>
        <input type="text" id="created_at" name="created_at" value="{{field.created_at * 1000 | date: 'd MMMM, y HH:mm'}}" readonly>
      </div>
      <div class="form-group">
        <button [disabled]="form.invalid">Update</button>
      </div>
    </form>
  }
</div>
