import {Component, DestroyRef, EventEmitter, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {FieldsService} from "../../services/fields.service";
import {Fields} from "../../models/Fields";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {load} from "@angular-devkit/build-angular/src/utils/server-rendering/esm-in-memory-loader/loader-hooks";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-fields-edit',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    DatePipe
  ],
  templateUrl: './fields-edit.component.html',
  styleUrl: './fields-edit.component.css'
})
export class FieldsEditComponent {
  title: string = 'Edit field';
  form!: FormGroup;
  field!: Fields;
  flashMessage: EventEmitter<{message:string, type:string}> = new EventEmitter<{message: string; type: string}>();
  loading: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private fieldsService: FieldsService,
    private destroyRef: DestroyRef
  ) {
    this.route.params.subscribe(params => {
      this.fieldsService.get(params['id']).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((r: any) => {
        if (!r.error) {
          this.field = r.data;


          this.form = this.formBuilder.group({
            type: new FormControl(this.field.type, Validators.required)
          });

          this.loading = false;
        }
      });
    });
  }

  // delete(): void {
  //   if (confirm('Are you sure?')) {
  //     this.fieldsService.delete(this.field).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((r:any) => {
  //       if (!r.error) {
  //         this.router.navigate(['/fields/' + this.field.type]);
  //         this.flashMessage.emit({message: 'Field was remove successful.', type: 'done'});
  //       } else {
  //         this.flashMessage.emit({message: 'There was an error while trying to delete this field.', type: 'error'});
  //       }
  //     });
  //   }
  // }

  onSubmit(form: FormGroup): void {
    if (this.form.valid) {

    }
  }
}
