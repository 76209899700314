import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Notes} from "../models/Notes";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class NotesService {
  constructor(private http: HttpClient) { }

  fetch(page: number) : Observable<any> {
    return this.http.get<any>(environment.backendUrl + '/notes?page=' + page, {withCredentials: true});
  }

  add(note: string) : Observable<Notes> {
    return this.http.post<Notes>(environment.backendUrl + '/notes', {text: note}, {withCredentials: true});
  }

  get(id: string): Observable<any> {
    return this.http.get<Observable<any>>(environment.backendUrl + '/notes/' + id, {withCredentials: true});
  }

  edit(note: Notes): Observable<any> {
    return this.http.post<Observable<any>>(environment.backendUrl + '/notes/' + note.id, {text: note.text}, {withCredentials: true});
  }

  delete(note: Notes) : Observable<Notes> {
    return this.http.delete<Notes>(environment.backendUrl + '/notes/' + note.id, {withCredentials: true});
  }
}
