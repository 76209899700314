import {Component, Input} from '@angular/core';
import {Notes} from "../../models/Notes";
import {DatePipe} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {NotesService} from "../../services/notes.service";
import {NoRecordsScreenComponent} from "../no-records-screen/no-records-screen.component";
import {MatTooltip} from "@angular/material/tooltip";
import {RouterLink} from "@angular/router";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
  selector: 'app-list-notes',
  standalone: true,
  imports: [
    DatePipe,
    MatIcon,
    NoRecordsScreenComponent,
    MatTooltip,
    RouterLink,
    MatProgressSpinner
  ],
  templateUrl: './list-notes.component.html',
  styleUrl: './list-notes.component.css'
})
export class ListNotesComponent {
  @Input() notes: Notes[] = [];
  @Input() loading?: boolean;

  constructor(private notesService: NotesService) {
  }

  delete(note: Notes, event: any) {
    this.notesService.delete(note).subscribe(() => {
      event.target.closest('.box').classList.add('removing');
      setTimeout(() => {
        this.notes = this.notes.filter(item => item.id !== note.id);
      }, 200);
    });
  }
}
