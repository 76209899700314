@if (!type) {
  <div class="box field-types income" [routerLink]="['/fields', 'income']">
    <div class="icon">
      <mat-icon class="material-icons-outlined">category</mat-icon>
    </div>
    <div class="info">
      <div class="title">
        <h2>Income fields</h2>
      </div>
      <p>Configure your income fields</p>
    </div>
  </div>
  <div class="box field-types spent" [routerLink]="['/fields', 'spent']">
    <div class="icon">
      <mat-icon class="material-icons-outlined">category</mat-icon>
    </div>
    <div class="info">
      <div class="title">
        <h2>Spent fields</h2>
        <p>Configure your spent fields</p>
      </div>
    </div>
  </div>
} @else {
  <div class="box">
    <div class="title">
      <h2>{{type | titlecase }} fields</h2>
      <div class="options">
        <div><mat-icon class="material-icons-outlined" (click)="add();" matTooltip="New group">add</mat-icon></div>
      </div>
    </div>
    @if (fields.length) {
      <div class="table-groups {{type}}_fields">
      <div class="table-group-row table-header">
        <div class="table-group-cell">Category</div>
        <div class="table-group-cell budget">
          @if (type === 'spent') {
            Budget
          } @else {
            Expected
          }
        </div>
        <div class="table-group-cell active_period">Active Period</div>
        <div class="table-group-cell actions"></div>
      </div>
      @for(item of fields; track item.id) {
        <div class="table-groups">
          <div class="table-group-row group-header" id="field_{{item.id}}">
            <div class="table-group-cell">
              @if (!item.id) {
                <app-custom-autocomplete
                  [items]="getCategories(null)"
                  [type]="type"
                  [propertyName]="'name'"
                  [selectedItem]="item"
                  [placeholder]="'Specify a group...'"
                  (selectedItemChange)="handleCategoryChange($event)"
                ></app-custom-autocomplete>
              } @else {
                {{item.name}}
              }
            </div>
            <div class="table-group-cell"></div>
            <div class="table-group-cell active_period"></div>
            <div class="table-group-cell"></div>
          </div>
          @for(field of item.fields; track field.id) {
            <div class="table-group-row" id="field_{{field.id}}">
              <div class="table-group-cell">
                <app-custom-autocomplete
                  [items]="getCategories(item.id)"
                  [type]="type"
                  [propertyName]="'name'"
                  [selectedItem]="{id: field.category_id, name: field.name}"
                  [placeholder]="'Specify a category...'"
                  (selectedItemChange)="handleCategoryChange($event, item, field.id)"
                ></app-custom-autocomplete>
              </div>
              <div class="table-group-cell editable">
                {{ currentBudget(field) | currency: user.currency : 'symbol-narrow' | fixCurrency }}
<!--                <input type="text" value="{{currentBudget(field)}}" (input)="updateBudget($event, field)"/>-->
                <mat-icon class="material-icons-outlined" (click)="openForEdit($event, field, item.name)" matTooltip="Edit">edit</mat-icon>
              </div>
              <div class="table-group-cell active_period">
                @for (month of this.activePeriods; let mid = $index; track mid) {
                  <span [ngClass]="{'selected': field.active_period && field.active_period.split(',').includes((mid + 1).toString())}" (click)="toggleActivePeriod($event, field)" id="key_{{(mid + 1)}}" matTooltip="{{month}}"></span>
                }
              </div>
              <div class="table-group-cell actions">
                <div class="btn copy" (click)="copy(field, item)" matTooltip="Copy"></div>
                <div class="btn delete" (click)="delete(field)" matTooltip="Delete"></div>
                <div class="btn edit" routerLink="/fields/{{type}}/{{field.id}}" matTooltip="Edit"></div>
              </div>
            </div>
          }
        </div>
      }
    </div>
    } @else {
      @if (loading) {
        <div class="loader">
          <mat-progress-spinner strokeWidth="2" mode="indeterminate"></mat-progress-spinner>
        </div>
      } @else {
        Add your first field by using the add button.
      }
    }
  </div>

}
