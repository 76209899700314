<div id="dashboard">
  <div class="dashboard_item cards" #cards>
    <div id="cards-list">
      <div class="card controls">
        <div class="info">
          <h2>
            <div id="back" (click)="previousMonth()"><mat-icon class="material-icons-outlined">arrow_back_ios_new</mat-icon></div>
            <span>{{dateAsString}}</span>
            <div id="forward" (click)="nextMonth()"><mat-icon class="material-icons-outlined">arrow_forward_ios_new</mat-icon></div>
          </h2>
        </div>
        <div class="chart-container">
          <div class="chart">
            @if (totalsChart) {
              @defer () {
                <apx-chart [series]="totalsChart.series"
                           [chart]="totalsChart.chart"
                           [xaxis]="totalsChart.xaxis"
                           [dataLabels]="totalsChart.dataLabels"
                           [grid]="totalsChart.grid"
                           [stroke]="totalsChart.stroke"
                           [title]="totalsChart.title"
                           [fill]="totalsChart.fill"
                           [plotOptions]="totalsChart.plotOptions"
                           [labels]="totalsChart.labels"
                           [tooltip]="totalsChart.tooltip"
                           [responsive]="totalsChart.responsive"
                ></apx-chart>
              }
            }
          </div>
        </div>
      </div>
      <div class="card totals">
        <div class="info">
          <h2 class="negative">{{data.total_spent | currency: user.currency:'symbol' | fixCurrency}}</h2>
          <p>Total Spent</p>
        </div>
        <div class="chart-container">
          <div class="chart">
          @if (transactionsSpent) {
            @defer () {
              <apx-chart [series]="transactionsSpent.series"
                         [chart]="transactionsSpent.chart"
                         [xaxis]="transactionsSpent.xaxis"
                         [dataLabels]="transactionsSpent.dataLabels"
                         [grid]="transactionsSpent.grid"
                         [stroke]="transactionsSpent.stroke"
                         [title]="transactionsSpent.title"
                         [fill]="transactionsSpent.fill"
                         [plotOptions]="transactionsSpent.plotOptions"
                         [labels]="transactionsSpent.labels"
                         [tooltip]="transactionsSpent.tooltip"
                         [markers]="transactionsSpent.markers"
              ></apx-chart>
            }
          }
          </div>
        </div>
      </div>
    </div>
    @if (carousel.getPages().length > 1) {
      <div id="cards-nav">
        <div (click)="previousStatsPage()" class="btn"><mat-icon class="material-icons-outlined">arrow_back_ios_new</mat-icon></div>
        <div class="pages">
          @for (page of carousel.getPages(); track $index) {
            <span (click)="carouselPage(page + 1)" [ngClass]="{'current': carousel.getCurrentPage() === (page + 1)}"></span>
          }
        </div>
        <div (click)="nextStatsPage()" class="btn"><mat-icon class="material-icons-outlined">arrow_forward_ios_new</mat-icon></div>
      </div>
    }
  </div>
  <div class="dashboard_item monthly-spent">
    <app-monthly-spent [date]="date$" [total_income]="data.total_income"></app-monthly-spent>
  </div>
  <div class="dashboard_item monthly-income">
    @defer (on viewport) {
      <app-monthly-income [date]="date$" [total_income]="data.total_income"></app-monthly-income>
    } @placeholder () {
      <div></div>
    }
  </div>
  <div class="dashboard_item year-summarize">
    @defer (on viewport) {
      <app-year-summarize [date]="date$" ></app-year-summarize>
    } @placeholder () {
      <div></div>
    }
  </div>
</div>
