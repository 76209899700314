import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ContainerScrollService {
  private data: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  scroll$: Observable<any> = this.data.asObservable();

  constructor() { }

  update(event: any): void {
    this.data.next(event);
  }
}
