import {Component, DestroyRef, OnInit} from '@angular/core';
import {CurrencyPipe, DatePipe, DecimalPipe} from "@angular/common";
import {FixCurrencyPipe} from "../../pipes/fix-currency.pipe";
import {MatIcon} from "@angular/material/icon";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatTooltip} from "@angular/material/tooltip";
import {NoRecordsScreenComponent} from "../../components/no-records-screen/no-records-screen.component";
import {RouterLink} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {User} from "../../models/User";
import {ScheduleTransactionService} from "../../services/schedule-transaction.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {ContainerScrollService} from "../../shared/container-scroll.service";

@Component({
  selector: 'app-scheduled-transactions',
  standalone: true,
  imports: [
    CurrencyPipe,
    DatePipe,
    DecimalPipe,
    FixCurrencyPipe,
    MatIcon,
    MatProgressSpinner,
    MatTooltip,
    NoRecordsScreenComponent,
    RouterLink
  ],
  templateUrl: './scheduled-transactions.component.html',
  styleUrl: './scheduled-transactions.component.css'
})
export class ScheduledTransactionsComponent implements OnInit {
  title: string = 'Scheduled Transactions';
  scheduledTransactions: any = [];
  page: number = 1;
  limit: number = 50;
  loading: boolean = true;
  hasMorePages: boolean = true;
  user: User;

  constructor(
    private scheduledTransactionsService: ScheduleTransactionService,
    private authService: AuthService,
    private containerScrollService: ContainerScrollService,
    private destroyRef: DestroyRef
  ) {
    this.user = this.authService.user;
  }

  ngOnInit() {
    this.getData();

    this.containerScrollService.scroll$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(event => {
      if (event !== null) {
        if ((event.target.scrollTop + event.target.offsetHeight) >= (event.target.scrollHeight - 300)) {
          if (!this.loading && this.hasMorePages) {
            ++this.page;
            this.getData();
          }
        }
      }
    });
  }

  private getData(): void {
    this.loading = true;
    this.scheduledTransactionsService.fetch(this.page).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((r: any) => {
      if (!r.error) {
        r.data.scheduled_transactions.forEach((item: any) => this.scheduledTransactions.push(item));
        this.hasMorePages = r.data.scheduled_transactions.length === this.limit;
        this.loading = false;
      }
    });
  }
}
