import {Component, EventEmitter, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {FeedbackService} from "../../services/feedback.service";

@Component({
  selector: 'app-feedback',
  standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule
    ],
  templateUrl: './feedback.component.html',
  styleUrl: './feedback.component.css'
})
export class FeedbackComponent implements OnInit {
  title: string = 'Feedback';
  form!: FormGroup;
  flashMessage: EventEmitter<{message:string, type:string}> = new EventEmitter<{message: string; type: string}>();

  constructor(
    private formBuilder: FormBuilder,
    private feedbackService: FeedbackService) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      feedback: new FormControl('', Validators.required)
    })
  }

  get feedback() {
    return this.form.controls['feedback'];
  }

  onSubmit(form: FormGroup): void {
    if (this.form.valid) {
      this.feedbackService.add(form.value.feedback).subscribe(r => {
        if (!r.error) {
          this.form.reset();
          this.flashMessage.emit({message: "Thank you for your feedback!", type: 'done'});
        }
      })
    }
  }
}
