<div id="signIn">
  <div id="form-container">
    <div id="message-holder">
      <img src="assets/images/login-screen.png"/>
    </div>
    <div id="form-holder">
      <form [formGroup]="form" (ngSubmit)="onSubmit(form)">
        <div class="heading">
          <h2>Welcome Back!</h2>
          <p>Sign in to continue to Budget $ense</p>
        </div>

        <div class="form-group">
          <label for="email">Email</label>
          <input type="text" formControlName="email" id="email" name="email" placeholder="Enter email">
          <span class="error">
            @if (submitted && form.invalid && (email.dirty || email.touched)) {
              @if (email.errors?.['required']) {
                Please enter your email address!
              }

              @if (email.errors?.['email']) {
                Please make sure that you entered a valid email address!
              }
            }
          </span>
        </div>

        <div class="form-group">
  <!--        <a id="forgot-password" routerLink="/forgot-password">Forgot Password?</a>-->
          <label for="password">Password</label>
          <input type="password" formControlName="password" class="form-control" id="password" name="password" placeholder="Enter password">
          <span class="error">
            @if (submitted && form.invalid && (password.dirty || password.touched)) {
              @if (password.errors?.['required']) {
                  Please enter your password!
                }
            }
          </span>
        </div>

        @if (errorMessage) {
          <p id="form-general-error-message">{{errorMessage}}</p>
        }

        <div class="form-group">
          <button>Sign In</button>
        </div>
      </form>
    </div>
    <div id="inbeta">
      The <span>Budget $ense</span> is in <span>beta</span> with limited access!<br>
    </div>
    <p>Don't have an account? <a routerLink="/sign-up" title="Sign up">Sign up</a> for free!</p>
  </div>
</div>

