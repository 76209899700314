import {Component, EventEmitter, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Transactions} from "../../models/Transactions";
import {FieldsService} from "../../services/fields.service";
import {FieldsByCategories} from "../../models/FieldsByCategories";
import {CustomSelectDropdownComponent} from "../custom-select-dropdown/custom-select-dropdown.component";
import {TransactionsService} from "../../services/transactions.service";
import {SelectDropdownComponent} from "../select-dropdown/select-dropdown.component";

@Component({
  selector: 'app-create-transaction',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CustomSelectDropdownComponent,
    SelectDropdownComponent
  ],
  templateUrl: './create-transaction.component.html',
  styleUrl: './create-transaction.component.css'
})
export class CreateTransactionComponent implements OnInit{
  title: string = 'Create new transaction';
  form!: FormGroup;
  fields: FieldsByCategories[] = [];
  loadedFieldsCategories: any = {};
  flashMessage: EventEmitter<{message:string, type:string}> = new EventEmitter<{message: string; type: string}>();
  longitude: number | null = null;
  latitude: number | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private fieldsService: FieldsService,
    private transactionsService: TransactionsService
  ) {

  }

  ngOnInit() {
    let date = new Date();
    let dateFormat = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0') + 'T' + String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0');

    this.form = this.formBuilder.group({
      created_at: new FormControl(dateFormat, [Validators.required]),
      type: new FormControl('spent', [Validators.required]),
      amount: new FormControl('', [Validators.required, Validators.pattern("^[0-9]+(\.[0-9]{1,2})?$")]),
      field_id: new FormControl('', [Validators.required]),
      comment: new FormControl('')
    });

    this.fieldsService.getGroupedByCategories(0, 'spent').subscribe(r => {
      this.fields = r.data.fields;
      this.loadedFieldsCategories['spent'] = r.data.fields;

      this.form.valueChanges.subscribe(data => {
        if (['income', 'spent'].includes(data.type) && !this.loadedFieldsCategories.hasOwnProperty(data.type)) {
          this.fieldsService.getGroupedByCategories(0, data.type).subscribe(r => {
            this.fields = r.data.fields;
            this.loadedFieldsCategories[data.type] = r.data.fields;
          });
        } else {
          this.fields = this.loadedFieldsCategories[data.type];
        }
      });
    });

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
        this.longitude = position.coords.longitude;
        this.latitude = position.coords.latitude;
      });
    }
  }

  toggleDatePicker(event: any) {
    event.target.showPicker();
  }

  get amount() {
    return this.form.controls['amount'];
  }

  get field() {
    return this.form.controls['field_id'];
  }

  get comment() {
    return this.form.controls['comment'];
  }

  get type() {
    return this.form.controls['type'];
  }

  onSubmit(form: FormGroup): void {
    if (this.form.valid) {
      const transaction = {} as Transactions;
      transaction.type = form.value.type;
      transaction.amount = form.value.amount;
      transaction.field_id = form.value.field_id;
      transaction.comment = form.value.comment;
      transaction.longitude = this.longitude;
      transaction.latitude = this.latitude;
      transaction.updated_at = 0;
      transaction.created_at = Math.ceil(new Date(form.value.created_at.replace(/-/g, '/').replace('T', ' ')).getTime() / 1000);

      this.transactionsService.add(transaction).subscribe((r:any) => {
        if (!r.error) {
          let date = new Date();
          this.form.reset({
            created_at: date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0') + 'T' + String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0'),
            amount: '',
            comment: '',
            type: form.value.type
          });

          this.flashMessage.emit({message: 'New transaction was created!', type: 'done'});
        } else {
          this.flashMessage.emit({message: 'Cannot create a new transaction at this moment.', type: 'error'});
        }
      });
    }
  }
}
