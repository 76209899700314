import {Component, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {NgClass} from "@angular/common";
import {FormGroup, ReactiveFormsModule} from "@angular/forms";

@Component({
  selector: 'app-custom-select-dropdown',
  standalone: true,
  imports: [
    NgClass,
    ReactiveFormsModule
  ],
  templateUrl: './custom-select-dropdown.component.html',
  styleUrl: './custom-select-dropdown.component.css'
})
export class CustomSelectDropdownComponent implements OnInit {
  @Input() name: string = '';
  @Input() items: any = [];
  @Input() form!: FormGroup;
  @Input() key!: string;
  @Input() selectedItemId: any = '';
  @Input() selectedItemName: string = '';
  toggleDropdownStatus: boolean = false;

  constructor(private elementRef: ElementRef) {

  }

  ngOnInit() {
    this.form.valueChanges.subscribe(data => {
        if (data.field_id === null) {
          this.selectedItemId = '';
          this.selectedItemName = '';
        }
    });
  }

  changeValue(item: any)
  {
    this.selectedItemId = item.id;
    this.selectedItemName = item.name;
    this.form.controls[this.key].setValue(this.selectedItemId);
  }

  toggleDropdown(event: any){
    if (
      !event.target.classList.contains('dropdown-group') &&
      (
        event.target.classList.contains('dropdown-item') ||
        event.target.classList.contains('dropdown-selected-value')
      )
    ) {
      this.toggleDropdownStatus = !this.toggleDropdownStatus;
    }
  }

  @HostListener('document:click', ['$event']) clickOutside(event: any): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.toggleDropdownStatus = false;
    }
  }

  @HostListener('document:keydown.escape', ['$event']) hitESCtoCloseDropdown(event: KeyboardEvent): void {
    this.toggleDropdownStatus = false;
  }
}
