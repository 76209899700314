import { Routes } from '@angular/router';
import {LayoutInterfaceComponent} from "./layouts/layout-interface/layout-interface.component";
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {notAuthGuard} from "./guards/not-auth.guard";
import {authGuard} from "./guards/auth.guard";
import {SignUpComponent} from "./pages/sign-up/sign-up.component";
import {ForgotPasswordComponent} from "./pages/forgot-password/forgot-password.component";
import {SignInComponent} from "./pages/sign-in/sign-in.component";
import {CalendarComponent} from "./pages/calendar/calendar.component";
import {NotesComponent} from "./pages/notes/notes.component";
import {FieldsComponent} from "./pages/fields/fields.component";
import {CreateNewListComponent} from "./components/create-new-list/create-new-list.component";
import {CreateNoteComponent} from "./components/create-note/create-note.component";
import {CreateTransactionComponent} from "./components/create-transaction/create-transaction.component";
import {signOutGuard} from "./guards/sign-out.guard";
import {TransactionsComponent} from "./pages/transactions/transactions.component";
import {MenuComponent} from "./pages/menu/menu.component";
import {TransactionsEditComponent} from "./pages/transactions-edit/transactions-edit.component";
import {FieldsOverviewComponent} from "./pages/fields-overview/fields-overview.component";
import {FieldsEditComponent} from "./pages/fields-edit/fields-edit.component";
import {AccountComponent} from "./pages/account/account.component";
import {FeedbackComponent} from "./pages/feedback/feedback.component";
import {NotesEditComponent} from "./pages/notes-edit/notes-edit.component";
import {ScheduleTransactionComponent} from "./pages/schedule-transaction/schedule-transaction.component";
import {ScheduledTransactionsComponent} from "./pages/scheduled-transactions/scheduled-transactions.component";

export const routes: Routes = [
  {path: '', component: LayoutInterfaceComponent, canActivate: [authGuard], children: [
      {path: '', pathMatch: 'full', redirectTo: 'dashboard'},
      {path: 'dashboard', component: DashboardComponent},
      {path: 'calendar', component: CalendarComponent},
      {path: 'transactions', component: TransactionsComponent},
      {path: 'transactions/:id', component: TransactionsEditComponent},
      {path: 'notes', component: NotesComponent},
      {path: 'notes/:id', component: NotesEditComponent},
      {path: 'fields', children: [
          {path: '', component: FieldsComponent},
          {path: ':type', component: FieldsComponent},
          {path: ':type/:id', component: FieldsEditComponent}
      ]},
      {path: 'fields-overview', children: [
          {path: '', component: FieldsOverviewComponent},
          {path: ':type', component: FieldsOverviewComponent}
      ]},
      {path: 'menu', component: MenuComponent},
      {path: 'account', component: AccountComponent},
      {path: 'scheduled-transactions', component: ScheduledTransactionsComponent},
      {path: 'scheduled-transactions/:id', component: ScheduledTransactionsComponent},
      {path: 'feedback', component: FeedbackComponent},
      {path: 'create-new', children: [
          {path: '', pathMatch: 'full', component: CreateNewListComponent},
          {path: 'transaction', component: CreateTransactionComponent},
          {path: 'note', component: CreateNoteComponent},
          {path: 'schedule-transaction', component: ScheduleTransactionComponent},
          {path: 'schedule-transaction/:id', component: ScheduleTransactionComponent}
      ]}
  ]},
  {path: 'sign-out', canActivate: [signOutGuard], children: []},
  {path: 'sign-in', component: SignInComponent, canActivate: [notAuthGuard]},
  // {path: 'sign-up', component: SignUpComponent, canActivate: [notAuthGuard]},
  {path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [notAuthGuard]},
  {path: '**', pathMatch: 'full', redirectTo: ''}
];
